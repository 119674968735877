/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, MouseEvent, ReactNode, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";

import clsx from "@features/utils/clsx";
import useMatchPath from "@hooks/useMatchPath";
import useSidebar from "@hooks/useSidebar";

interface DashboardNavigationProps {
  item?: boolean;
  subnav?: boolean;
  // eslint-disable-next-line no-unused-vars
  onClick?: (event: React.MouseEvent) => void;
  preventDefault?: boolean;
  icon?: string;
  className?: string;
  href?: string;
  nestedHref?: string;
  depth?: string;
  children: ReactNode;
  open?: boolean;
  button?: boolean;
}

const DashboardNavigation: FC<DashboardNavigationProps> = ({
  item,
  href,
  depth,
  subnav,
  onClick,
  children,
  className,
  preventDefault,
  icon,
  nestedHref = "none",
  button,
  ...rest
}) => {
  const active = useMatchPath(href);
  const nestedActive = useMatchPath(nestedHref);
  const focus = useMemo(
    () => (active || nestedActive) && !subnav,
    [nestedActive, active, subnav]
  );
  const [sidebarActive, sidebarActions] = useSidebar();
  const isContactUsSection = window.location.href.includes("#contact");

  const Component = useMemo(
    () => (!href ? "p" : (props: any) => <Link to={href} {...props} />),
    [href]
  );

  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (onClick) {
        onClick(event);
      }

      if (subnav) {
        return;
      }

      if (preventDefault) {
        return;
      }

      if (sidebarActive) {
        (
          sidebarActions as {
            on: () => void;
            off: () => void;
            toggle: () => void;
          }
        ).off();
      }
    },
    [subnav, onClick, sidebarActive, sidebarActions, preventDefault]
  );

  if (!item) {
    return (
      <nav className={clsx("flex-1 space-y-5", className)}>{children}</nav>
    );
  }

  return (
    <Component
      className={clsx(
        "group flex cursor-pointer items-center font-medium rounded-md text-sm",
        {
          " py-3 px-2": !button,
          "text-gray-900 bg-teal-500 bg-opacity-10": focus && !button,
          "text-gray-900 hover:bg-teal-500 hover:bg-opacity-5":
            !focus && !button,
          "pl-11": depth === "1",
          "h-10": !icon,
          "!text-gray-900 !bg-transparent hover:!bg-transparent":
            isContactUsSection
        },

        className
      )}
      onClick={handleClick}
      {...rest}
    >
      {icon ? (
        <img
          src={icon}
          alt='dashboard icon'
          className='mr-3 h-5 w-5 text-gray-700'
        />
      ) : null}
      {children}
    </Component>
  );
};

export default DashboardNavigation;
