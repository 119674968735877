import React, { FC, ReactNode } from "react";

import { LockOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import { DashboardNavigation } from "src/layout/dashboard";

interface InstitutionNavItemProps {
  href: string;
  icon: string;
  children: ReactNode;
  isPartner: boolean;
}

const InstitutionNavItem: FC<
  InstitutionNavItemProps & { onLockedClick?: () => void }
> = ({ href, icon, children, isPartner, onLockedClick }) => (
  <Box
    onClick={() => {
      if (!isPartner && onLockedClick) {
        onLockedClick();
      }
    }}
    sx={{
      position: "relative",
      width: "100%",
      opacity: isPartner ? 1 : 0.7,
      "&:hover": {
        cursor: isPartner ? "pointer" : "not-allowed"
      }
    }}
  >
    <DashboardNavigation item href={isPartner ? href : "#"} icon={icon}>
      {children}
      {!isPartner && (
        <LockOutlined
          sx={{
            position: "absolute",
            right: 8,
            top: "50%",
            transform: "translateY(-50%)",
            fontSize: 16,
            color: "text.secondary"
          }}
        />
      )}
    </DashboardNavigation>
  </Box>
);

export default InstitutionNavItem;
