import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Table, {
  TableBody,
  TableColumn,
  TableHead,
  TableRow
} from "@components/Table";
import Pagination from "@features/Search/components/Pagination";
import { columns } from "@features/utils/constants";
import { PublicResource } from "@interfaces/resource-values";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import filter from "@static/icons/filter.svg";
import { AppDispatch } from "src/services/store";
import { APP_COLORS } from "src/styles/colors";

import ColumnSelectDialog from "./ColumnSelectDialog";
import DatabaseHeader from "./DatabaseHeader";
import FilterSidebar from "./FilterSidebar";
import SuggestBiobankModal from "./SuggestBiobankModal";
import TableControlBar from "./TableControlBar";

import {
  fetchPublicResources,
  selectLoading,
  selectPaginationMetadata,
  selectResources,
  suggestBioBank
} from "../externalDatabaseSlice";

type FilterOptions =
  | "Resource Category"
  | "Species"
  | "Tissue of interest"
  | "Geographic Location";

const filterKeyMapping = {
  "Resource Category": "category",
  Species: "species",
  "Tissue of interest": "tissue",
  "Geographic Location": "geographic_location"
};

const filterOptions = [
  "Resource Category",
  "Species",
  "Tissue of interest",
  "Geographic Location",
  "Research area",
  "Disease condition",
  "Storage condition",
  "Sex",
  "Country"
];

const options = {
  "Resource Category": [
    "Animal model",
    "Cell line",
    "Biospecimen",
    "Plasmid",
    "Antibody",
    "Equipment"
  ],
  Species: ["No options available"],
  "Tissue of interest": ["No options available"],
  "Geographic Location": ["No options available"]
};

const ExternalDatabaseTable: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const resources = useSelector(selectResources);
  const paginationMetadata = useSelector(selectPaginationMetadata);
  const loading = useSelector(selectLoading);

  const [selectedColumns, setSelectedColumns] = useState<
    (keyof PublicResource)[]
  >([
    "category",
    "name",
    "number_of_participants",
    "age_of_participants",
    "biospecimen_types",
    "request_link"
  ]);

  const [showColumnSelectDialog, setShowColumnSelectDialog] = useState(false);
  const [filterSidebarOpen, setFilterSidebarOpen] = useState(false);
  const [suggestBiobankModalOpen, setSuggestBiobankModalOpen] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState<
    Record<FilterOptions, string[]>
  >({
    "Resource Category": [],
    Species: [],
    "Tissue of interest": [],
    "Geographic Location": []
  });
  const [searchQuery, setSearchQuery] = useState("");

  const handleColumnSelect = (updatedColumns: string[]) => {
    setSelectedColumns(updatedColumns as (keyof PublicResource)[]);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleSelectionChange = (filter: string, value: string[]) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [filter as FilterOptions]: value
    }));
  };

  const handleDeleteTag = (filter: string, tag: string) => {
    setSelectedFilters((prev) => {
      const typedFilter = filter as FilterOptions;

      if (Object.keys(prev).includes(typedFilter)) {
        return {
          ...prev,
          [typedFilter]: prev[typedFilter].filter((item) => item !== tag)
        };
      }

      return prev;
    });
  };

  const handleClearAllFilters = () => {
    setSelectedFilters({
      "Resource Category": [],
      Species: [],
      "Tissue of interest": [],
      "Geographic Location": []
    });
  };

  const handleSubmitBiobank = async (values: {
    biobank_name: string;
    biobank_resource_link: string;
  }) => {
    await dispatch(suggestBioBank(values));
    setSuggestBiobankModalOpen(false);
  };

  useEffect(() => {
    const activeFilters = Object.entries(selectedFilters)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_, values]) => values.length > 0)
      .reduce(
        (acc, [key, values]) => {
          const backendKey =
            filterKeyMapping[key as FilterOptions] ||
            key.toLowerCase().replace(/\s+/g, "_");

          acc[backendKey] = values.length === 1 ? values[0] : values;
          return acc;
        },
        {} as Record<string, string | string[]>
      );

    const params = {
      filters: Object.keys(activeFilters).length
        ? JSON.stringify(activeFilters)
        : undefined,
      search: searchQuery || undefined
    };

    dispatch(fetchPublicResources({ params }));
  }, [dispatch, selectedFilters, searchQuery]);

  const debouncedOnPageChange = useCallback(
    (newPage: number) => {
      dispatch(
        fetchPublicResources({
          params: {
            page: newPage,
            limit: paginationMetadata.limit
          }
        })
      );
    },
    [dispatch, paginationMetadata.limit]
  );

  return (
    <>
      <DatabaseHeader
        onSuggestBioBank={() => setSuggestBiobankModalOpen(true)}
      />
      <Box
        sx={{
          display: { xs: "none", sm: "flex" },
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "stretch", sm: "center" },
          p: 2,
          border: "1px solid #ddd",
          borderRadius: "8px",
          backgroundColor: "#fff",
          marginBottom: 4,
          gap: { xs: 2, sm: 1 }
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: { xs: 1, sm: 0 }
          }}
        >
          <Typography
            fontWeight='500'
            fontSize='0.875rem'
            sx={{ marginRight: 1 }}
          >
            Filter by:
          </Typography>
          <IconButton
            onClick={() => setFilterSidebarOpen(true)}
            sx={{ padding: { xs: 0.5, sm: 1 } }}
          >
            <img src={filter} alt='Filter' style={{ width: 20, height: 20 }} />
          </IconButton>
        </Box>

        <Grid
          container
          spacing={1}
          sx={{
            flexGrow: 1,
            width: { xs: "100%", sm: "auto" },
            display: { xs: "none", sm: "flex" }
          }}
        >
          {filterOptions.map((filter) => (
            <Grid item xs={6} sm={3} md={1.7} key={filter}>
              <Button
                variant='outlined'
                fullWidth
                sx={{
                  textTransform: "none",
                  fontWeight: 500,
                  fontSize: "0.8rem",
                  padding: "4px 8px",
                  minHeight: "32px",
                  color: "#000",
                  borderColor: "#ccc",
                  borderRadius: "16px",
                  "&:hover": {
                    borderColor: APP_COLORS.primaryMain
                  }
                }}
                onClick={() => setFilterSidebarOpen(true)}
              >
                {filter}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Box>
      <TableControlBar
        searchQuery={searchQuery}
        totalResults={paginationMetadata.total_count}
        onSearchChange={handleSearchChange}
        onOpenFilter={() => setFilterSidebarOpen(true)}
        onOpenTableDisplayOptions={() => setShowColumnSelectDialog(true)}
      />

      <Table disableBorderBottom className='lg:rounded-md w-full'>
        <TableHead>
          <TableRow>
            {selectedColumns.map((column) => (
              <TableColumn heading key={column}>
                {columns.find((col) => col.id === column)?.label}
              </TableColumn>
            ))}
            <TableColumn heading>Contact</TableColumn>
          </TableRow>
        </TableHead>
        <TableBody>
          {resources.map((row, index) => (
            <TableRow key={index}>
              {selectedColumns.map((column) => {
                const columnLabel = columns.find(
                  (col) => col.id === column
                )?.label;
                return (
                  <TableColumn key={column}>
                    {columnLabel === "Request Link" ? (
                      <a
                        href={(row as PublicResource)[column]}
                        target='_blank'
                        rel='noopener noreferrer'
                        style={{ color: "black", textDecoration: "underline" }}
                      >
                        {(row as PublicResource)[column] || "N/A"}
                      </a>
                    ) : (
                      // eslint-disable-next-line prettier/prettier
                      (row as PublicResource)[column] ?? "N/A"
                    )}
                  </TableColumn>
                );
              })}
              <TableColumn>
                <Button
                  color='primary'
                  size='medium'
                  variant='outlined'
                  sx={{
                    borderRadius: 12,
                    fontSize: 12,
                    borderColor: APP_COLORS.buttonGray,
                    color: APP_COLORS.buttonGrayText,
                    "&:hover": {
                      borderColor: APP_COLORS.darkGray,
                      color: APP_COLORS.darkGray
                    }
                  }}
                  onClick={() => {
                    const email = row.contact_email;
                    if (email) {
                      window.open(`mailto:${email}`, "_blank");
                    }
                  }}
                >
                  Contact
                </Button>
              </TableColumn>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Pagination
        className='border border-t-0 rounded'
        total={paginationMetadata.total_count}
        totalPages={paginationMetadata.total_pages}
        page={paginationMetadata.page}
        setPage={debouncedOnPageChange}
        loading={loading}
      />

      <ColumnSelectDialog
        open={showColumnSelectDialog}
        onClose={() => setShowColumnSelectDialog(false)}
        selectedColumns={selectedColumns}
        onColumnSelect={handleColumnSelect}
      />

      <FilterSidebar
        open={filterSidebarOpen}
        onClose={() => setFilterSidebarOpen(false)}
        filterOptions={filterOptions}
        options={options}
        selectedFilters={selectedFilters}
        onSelectionChange={handleSelectionChange}
        onDeleteTag={handleDeleteTag}
        totalResults={paginationMetadata.total_count}
        onClearAll={handleClearAllFilters}
      />

      <SuggestBiobankModal
        open={suggestBiobankModalOpen}
        onClose={() => setSuggestBiobankModalOpen(false)}
        onSubmit={handleSubmitBiobank}
      />
    </>
  );
};

export default ExternalDatabaseTable;
