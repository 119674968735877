import React from "react";

import { List, Search } from "@mui/icons-material";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import filter from "@static/icons/filter.svg";

interface TableControlBarProps {
  searchQuery: string;
  totalResults: number;
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onOpenFilter: () => void;
  onOpenTableDisplayOptions: () => void;
}

const TableControlBar: React.FC<TableControlBarProps> = ({
  searchQuery,
  totalResults,
  onSearchChange,
  onOpenFilter,
  onOpenTableDisplayOptions
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "16px",
        backgroundColor: (theme) => theme.palette.grey[50],
        borderColor: (theme) => theme.palette.grey[300],
        flexDirection: { xs: "column", sm: "row" },
        gap: 2
      }}
    >
      <TextField
        variant='outlined'
        size='small'
        placeholder='Search public database'
        value={searchQuery}
        onChange={onSearchChange}
        InputProps={{
          startAdornment: (
            <Search sx={{ color: (theme) => theme.palette.grey[500], mr: 1 }} />
          )
        }}
        sx={{
          width: { xs: "100%", sm: "300px" },
          backgroundColor: "#FFF",
          borderRadius: "5px"
        }}
      />

      <Typography
        variant='body2'
        sx={{
          flex: 1,
          textAlign: { xs: "center", sm: "center" }
        }}
      >
        Showing {totalResults} results
      </Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: { xs: "row", sm: "column" },
          gap: 2,
          marginBottom: { xs: 1, sm: 0 }
        }}
      >
        <Box
          sx={{
            display: { xs: "flex", sm: "none" },
            alignItems: "center"
          }}
        >
          <Typography
            fontWeight='500'
            fontSize='0.875rem'
            sx={{ marginRight: 1 }}
          >
            Filter by:
          </Typography>
          <IconButton
            onClick={onOpenFilter}
            sx={{ padding: { xs: 0.5, sm: 1 } }}
          >
            <img src={filter} alt='Filter' style={{ width: 20, height: 20 }} />
          </IconButton>
        </Box>
        <Button
          variant='contained'
          onClick={onOpenTableDisplayOptions}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            backgroundColor: (theme) => theme.palette.grey[200],
            color: (theme) => theme.palette.text.primary,
            borderRadius: "8px",
            padding: "8px 16px",
            "&:hover": {
              backgroundColor: (theme) => theme.palette.grey[300]
            }
          }}
        >
          <List sx={{ color: (theme) => theme.palette.grey[700] }} />
          Table Display Options
        </Button>
      </Box>
    </Box>
  );
};

export default TableControlBar;
