import React, { ElementType, FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import TSAppBar from "@components/AppBar/TSAppBar";
import InstitutionNavItem from "@components/Dasboard/InstitutionNavItem";
import UserBlock from "@components/UserBlock/UserBlock";
import { getUser } from "@features/Auth/authSlice";
import {
  getInstitutionById,
  getLabInfo,
  selectInstitutionById
} from "@features/Lab/labslice";
import {
  socketJoinRoom,
  socketLeaveRoom
} from "@features/sockets/socket-events";
import useRequireSession from "@hooks/useRequireSession";
import { Add } from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import KeyboardDoubleArrowLefttIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import {
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
  useMediaQuery
} from "@mui/material";
import donate from "@static/icons/donate-icon.svg";
import externalDatabase from "@static/icons/external-database.svg";
import home from "@static/icons/home.svg";
import labSlab from "@static/icons/labicon.svg";
import lock from "@static/icons/lock.svg";
import metrics from "@static/icons/metrics.svg";
import university from "@static/icons/portal-uni-Icon.svg";
import magnifier from "@static/icons/sidebar-magni-Icon.svg";
import transfer from "@static/icons/transfer.svg";
import people from "@static/icons/user-group.svg";
import userIcon from "@static/icons/user-icon.svg";
import {
  ENABLE_METRICS_FEATURE,
  ENABLE_PUBLIC_DATABASE_FEATURE
} from "src/config";
import { AppDispatch } from "src/services/store";
import { APP_COLORS } from "src/styles/colors";

import DashboardBody from "./DashboardBody";
import DashboardNavigation from "./DashboardNavigation";
import DashboardProvider from "./DashboardProvider";
import DashboardSidebar from "./DashboardSidebar";

interface DashboardRouteProps {
  redirectUnauthorized: boolean;
  component: ElementType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const DashboardRoute: FC<DashboardRouteProps> = ({
  component: Component,
  redirectUnauthorized = false,
  ...rest
}) => {
  const [userId] = useRequireSession({
    redirect: redirectUnauthorized
  });

  const dispatch: AppDispatch = useDispatch();
  const currentUser = useSelector(getUser);
  const institution = useSelector(selectInstitutionById);
  const isPartner = institution.is_partner;
  const labId: string = currentUser.labId;
  const isLoggedIn = Boolean(currentUser.id !== undefined);
  const [restrictAccess, setRestrictAccess] = useState(false);

  const [showSidebar, setShowSidebar] = useState(false);

  const handleLockedClick = () => {
    setRestrictAccess(true);
  };
  const handleUnlockedClick = () => {
    setRestrictAccess(false);
  };

  useEffect(() => {
    if (currentUser?.institutionId) {
      dispatch(
        getInstitutionById({ institutionId: currentUser.institutionId })
      );
    }
  }, [dispatch, currentUser.institutionId]);

  useEffect(() => {
    document.body.style.overflowY = "hidden";
    if (isLoggedIn) {
      if (currentUser.institutionStatus === "approved") {
        dispatch(getLabInfo(labId));
      }
      dispatch(socketJoinRoom(currentUser.id));
    }
    return () => {
      dispatch(socketLeaveRoom(currentUser.id));
      document.body.style.overflowY = "auto";
    };
  }, [dispatch, labId, isLoggedIn]);

  const isSuperAdmin = currentUser.roles?.includes("super_admin");
  const isInstitutionAdmin = currentUser.roles?.includes("institution_admin");

  const mobileView = useMediaQuery("(max-width:768px)");
  const sidebarIconStyle = {
    borderRadius: 0,
    width: "100%",
    "&:hover": {
      background: "none"
    }
  };

  return (
    <DashboardProvider>
      {userId ? (
        <>
          {!showSidebar ? (
            <DashboardSidebar
              currentUser={currentUser}
              hideInstitution={true}
              showSidebar={showSidebar}
            >
              <IconButton
                title='Open Sidebar'
                aria-label='Open Sidebar'
                onClick={() => {
                  setShowSidebar(true);
                }}
                sx={{
                  position: "absolute",
                  top: 0,
                  margin: 0,
                  backgroundColor: APP_COLORS.white,
                  borderRadius: 0,
                  borderBottom: `1px solid ${APP_COLORS.lightGray}`,
                  zIndex: 2,
                  width: "100%"
                }}
              >
                <KeyboardDoubleArrowRightIcon />
              </IconButton>

              <DashboardNavigation
                item
                href={`/dashboard/home`}
                onClick={handleUnlockedClick}
              >
                <IconButton
                  aria-label={"Public Feed"}
                  sx={{
                    borderRadius: 0,
                    width: "100%",
                    "&:hover": {
                      background: "none"
                    }
                  }}
                >
                  <img
                    src={home}
                    alt={"Public Feed"}
                    title={"Public Feed"}
                    style={{
                      width: 16
                    }}
                  />
                </IconButton>
              </DashboardNavigation>

              {ENABLE_PUBLIC_DATABASE_FEATURE && (
                <DashboardNavigation
                  item
                  href='/dashboard/external-database'
                  onClick={handleUnlockedClick}
                >
                  <IconButton
                    aria-label={" Public Database"}
                    sx={{
                      borderRadius: 0,
                      width: "100%",
                      "&:hover": {
                        background: "none"
                      }
                    }}
                  >
                    <img
                      src={externalDatabase}
                      alt={"Public Database"}
                      title={"Public Database"}
                      style={{
                        width: 16
                      }}
                    />
                  </IconButton>
                </DashboardNavigation>
              )}
              <DashboardNavigation
                item
                href={`/dashboard/member/${userId}`}
                onClick={handleUnlockedClick}
              >
                <IconButton aria-label={"My Profile"} sx={sidebarIconStyle}>
                  <img
                    src={userIcon}
                    alt={"My Profile"}
                    title={"My Profile"}
                    style={{
                      width: 16
                    }}
                  />
                </IconButton>
              </DashboardNavigation>
              <InstitutionNavItem
                href='/dashboard'
                icon=''
                isPartner={isPartner === true}
                onLockedClick={handleLockedClick}
              >
                <IconButton
                  aria-label={"Recent Activity"}
                  sx={sidebarIconStyle}
                >
                  <img
                    src={university}
                    alt={"Recent Activity"}
                    title={"Recent Activity"}
                    style={{
                      width: 16
                    }}
                  />
                </IconButton>
              </InstitutionNavItem>
              <InstitutionNavItem
                href='/dashboard/institution-database'
                icon=''
                isPartner={isPartner === true}
                onLockedClick={handleLockedClick}
              >
                <IconButton
                  aria-label={"Institutional Database"}
                  sx={sidebarIconStyle}
                >
                  <img
                    src={magnifier}
                    alt={"Institutional Database"}
                    title={"Institutional Database"}
                    style={{
                      width: 16
                    }}
                  />
                </IconButton>
              </InstitutionNavItem>
              <InstitutionNavItem
                href={`/dashboard/lab?lab_id=${labId}`}
                icon=''
                isPartner={isPartner === true}
                onLockedClick={handleLockedClick}
              >
                <IconButton
                  aria-label={"My Lab's Profile"}
                  sx={sidebarIconStyle}
                >
                  <img
                    src={labSlab}
                    alt={"My Lab's Profile"}
                    title={"My Lab's Profile"}
                    style={{
                      width: 16
                    }}
                  />
                </IconButton>
              </InstitutionNavItem>
              <InstitutionNavItem
                href='/dashboard/institution-search'
                icon=''
                isPartner={isPartner === true}
                onLockedClick={handleLockedClick}
              >
                <IconButton
                  aria-label={"Lab & People Search"}
                  sx={sidebarIconStyle}
                >
                  <img
                    src={people}
                    alt={"Lab & People Search"}
                    title={"Lab & People Search"}
                    style={{
                      width: 16
                    }}
                  />
                </IconButton>
              </InstitutionNavItem>
              <InstitutionNavItem
                href='/dashboard/transfer'
                icon=''
                isPartner={isPartner === true}
                onLockedClick={handleLockedClick}
              >
                <IconButton
                  aria-label={"Start A Transfer"}
                  sx={sidebarIconStyle}
                >
                  <img
                    src={transfer}
                    alt={"Start A Transfer"}
                    title={"Start A Transfer"}
                    style={{
                      width: 16
                    }}
                  />
                </IconButton>
              </InstitutionNavItem>
              <InstitutionNavItem
                href='/dashboard/donate'
                icon=''
                isPartner={isPartner === true}
                onLockedClick={handleLockedClick}
              >
                <IconButton
                  aria-label={"Donate Mice For Training"}
                  sx={sidebarIconStyle}
                >
                  <img
                    src={donate}
                    alt={"Donate Mice For Training"}
                    title={"Donate Mice For Training"}
                    style={{
                      width: 16
                    }}
                  />
                </IconButton>
              </InstitutionNavItem>
              {(isSuperAdmin || isInstitutionAdmin) && (
                <>
                  <InstitutionNavItem
                    href='/dashboard/institution-users'
                    icon=''
                    isPartner={isPartner === true}
                    onLockedClick={handleLockedClick}
                  >
                    <IconButton
                      aria-label={"Approve New Users"}
                      sx={sidebarIconStyle}
                    >
                      <img
                        src={lock}
                        alt={"Approve New Users"}
                        title={"Approve New Users"}
                        style={{
                          width: 16
                        }}
                      />
                    </IconButton>
                  </InstitutionNavItem>
                  {ENABLE_METRICS_FEATURE && (
                    <InstitutionNavItem
                      href='/dashboard/metrics'
                      icon=''
                      isPartner={isPartner === true}
                      onLockedClick={handleLockedClick}
                    >
                      <IconButton aria-label={"Metrics"} sx={sidebarIconStyle}>
                        <img
                          src={metrics}
                          alt={"Metrics"}
                          title={"Metrics"}
                          style={{
                            width: 16
                          }}
                        />
                      </IconButton>
                    </InstitutionNavItem>
                  )}
                </>
              )}

              <Divider />
              <DashboardNavigation
                item
                href={"/dashboard/create"}
                className='rounded-none bg-white'
              >
                <IconButton
                  title='Create a Post'
                  aria-label='Create a Post'
                  sx={{
                    backgroundColor: "none",
                    borderRadius: 0,
                    width: "100%",
                    "&:hover": {
                      background: "none"
                    }
                  }}
                >
                  <AddCircleIcon color='primary' />
                </IconButton>
              </DashboardNavigation>
              <Divider />
            </DashboardSidebar>
          ) : (
            <DashboardSidebar
              currentUser={currentUser}
              showSidebar={showSidebar}
            >
              <IconButton
                aria-label='Close sidebar'
                onClick={() => {
                  setShowSidebar(false);
                }}
                sx={{
                  position: "absolute",
                  top: 0,
                  right: mobileView ? "calc(100% - 280px)" : "4px",
                  backgroundColor: APP_COLORS.white,
                  borderRadius: 1,
                  marginTop: 0
                }}
              >
                <KeyboardDoubleArrowLefttIcon />
              </IconButton>
              <Divider sx={{ marginBottom: 4 }} />
              <DashboardNavigation>
                <Stack px={3} spacing={0.5}>
                  <Typography
                    color={APP_COLORS.subtitleColor}
                    fontWeight={600}
                    px={1}
                  >
                    Home
                  </Typography>
                  <DashboardNavigation
                    item
                    href={`/dashboard/home`}
                    icon={home}
                    onClick={handleUnlockedClick}
                  >
                    Public Feed
                  </DashboardNavigation>
                  {ENABLE_PUBLIC_DATABASE_FEATURE && (
                    <DashboardNavigation
                      item
                      href='/dashboard/external-database'
                      icon={externalDatabase}
                      onClick={handleUnlockedClick}
                    >
                      Public Database
                    </DashboardNavigation>
                  )}

                  <DashboardNavigation
                    item
                    href={`/dashboard/member/${userId}`}
                    icon={userIcon}
                    onClick={handleUnlockedClick}
                  >
                    My Profile
                  </DashboardNavigation>
                  <DashboardNavigation item href={"/dashboard/create"} button>
                    <Button
                      variant='outlined'
                      size='small'
                      sx={{
                        borderRadius: 3,
                        px: 1
                      }}
                    >
                      <Stack direction={"row"} alignItems={"center"}>
                        <Add sx={{ width: "17px" }} className='mr-1' />
                        <Typography pr={1}>Create a post</Typography>
                      </Stack>
                    </Button>
                  </DashboardNavigation>
                </Stack>
                <Divider />
                <Stack px={3} spacing={0.5}>
                  <Typography
                    color={APP_COLORS.subtitleColor}
                    fontWeight={600}
                    px={1}
                  >
                    My Institution
                  </Typography>
                  <InstitutionNavItem
                    href='/dashboard'
                    icon={university}
                    isPartner={isPartner === true}
                    onLockedClick={handleLockedClick}
                  >
                    Recent Activity
                  </InstitutionNavItem>
                  <InstitutionNavItem
                    href='/dashboard/institution-database'
                    icon={magnifier}
                    isPartner={isPartner === true}
                    onLockedClick={handleLockedClick}
                  >
                    Institutional Database
                  </InstitutionNavItem>
                  <InstitutionNavItem
                    href={`/dashboard/lab?lab_id=${labId}`}
                    icon={labSlab}
                    isPartner={isPartner === true}
                    onLockedClick={handleLockedClick}
                  >
                    My Lab&apos;s Profile
                  </InstitutionNavItem>
                  <InstitutionNavItem
                    href='/dashboard/institution-search'
                    icon={people}
                    isPartner={isPartner === true}
                    onLockedClick={handleLockedClick}
                  >
                    Lab & People Search
                  </InstitutionNavItem>
                  <InstitutionNavItem
                    href='/dashboard/transfer'
                    icon={transfer}
                    isPartner={isPartner === true}
                    onLockedClick={handleLockedClick}
                  >
                    Start A Transfer
                  </InstitutionNavItem>
                  <InstitutionNavItem
                    href='/dashboard/donate'
                    icon={donate}
                    isPartner={isPartner === true}
                    onLockedClick={handleLockedClick}
                  >
                    Donate Mice For Training
                  </InstitutionNavItem>
                </Stack>
                {(isSuperAdmin || isInstitutionAdmin) && (
                  <>
                    <Divider />
                    <Stack px={3} spacing={0.5}>
                      <Typography
                        color={APP_COLORS.subtitleColor}
                        fontWeight={600}
                        px={1}
                      >
                        Admin
                      </Typography>
                      <InstitutionNavItem
                        href='/dashboard/institution-users'
                        icon={lock}
                        isPartner={isPartner === true}
                        onLockedClick={handleLockedClick}
                      >
                        Approve New Users
                      </InstitutionNavItem>
                      {ENABLE_METRICS_FEATURE && (
                        <InstitutionNavItem
                          href='/dashboard/metrics'
                          icon={metrics}
                          isPartner={isPartner === true}
                          onLockedClick={handleLockedClick}
                        >
                          Metrics
                        </InstitutionNavItem>
                      )}
                    </Stack>
                  </>
                )}
              </DashboardNavigation>
              <Divider sx={{ my: 3 }} />
              <UserBlock currentUser={currentUser} />
            </DashboardSidebar>
          )}
        </>
      ) : null}
      <DashboardBody restrictAccess={restrictAccess} showSidebar={showSidebar}>
        <TSAppBar inDashboard />
        <Component {...rest} />
      </DashboardBody>
    </DashboardProvider>
  );
};

export default DashboardRoute;
