import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import NotificationsCard from "@components/AppBar/NotificationsCard";
import { logout } from "@features/Auth/authSlice";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import about from "@static/icons/about.svg";
import home from "@static/icons/home.svg";
import logoutSVG from "@static/icons/logout.svg";
import messaging from "@static/icons/messaging.svg";
import notification from "@static/icons/notification.svg";
import { ENABLE_MESSAGING_FEATURE } from "src/config";
import { AppDispatch } from "src/services/store";
import { APP_COLORS } from "src/styles/colors";

interface MobileMenuProps {
  isLoggedIn: boolean;
}

const MobileMenu: React.FC<MobileMenuProps> = ({ isLoggedIn }) => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    useState<null | HTMLElement>(null);

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleLoginLogout = async (event: React.MouseEvent) => {
    event.preventDefault();
    handleMobileMenuClose();

    if (isLoggedIn) {
      const logoutResponse = await dispatch(logout());
      if (logoutResponse.payload.logoutUrl) {
        return (window.location.href = logoutResponse.payload.logoutUrl);
      }
      navigate("/");
    } else {
      setTimeout(() => navigate("/get-started"), 0);
    }
  };

  const handleNavigation = (path: string) => {
    handleMobileMenuClose();
    setTimeout(() => navigate(path), 0);
  };

  return (
    <Box sx={{ display: { xs: "flex", md: "none" } }}>
      <Button
        sx={{ p: 0, minWidth: "unset" }}
        size='small'
        aria-label='show more'
        aria-controls='mobile-menu'
        aria-haspopup='true'
        onClick={handleMobileMenuOpen}
        color='inherit'
      >
        <MoreIcon color='primary' />
      </Button>
      <Menu
        id='mobile-menu'
        anchorEl={mobileMoreAnchorEl}
        keepMounted
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <MenuItem
          onClick={() => handleNavigation(isLoggedIn ? "/dashboard/home" : "/")}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <img src={home} alt='Home' style={{ width: 20, height: 20 }} />
          <Typography fontSize={12}>Home</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => handleNavigation("/about")}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <img src={about} alt='About' style={{ width: 20, height: 20 }} />
          <Typography fontSize={12}>About</Typography>
        </MenuItem>
        {isLoggedIn ? (
          <Box>
            {ENABLE_MESSAGING_FEATURE && (
              <MenuItem
                onClick={() => handleNavigation("/dashboard/messaging")}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"
                }}
              >
                <img
                  src={messaging}
                  alt='Messaging'
                  style={{ width: 20, height: 20 }}
                />
                <Typography fontSize={12}>Messaging</Typography>
              </MenuItem>
            )}
            <MenuItem
              onClick={() => handleNavigation("/dashboard/notifications")}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              <img
                src={notification}
                alt='Notifications'
                style={{ width: 20, height: 20 }}
              />
              <Typography fontSize={12}>Notifications</Typography>
              <NotificationsCard />
            </MenuItem>
            <MenuItem
              onClick={handleLoginLogout}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                style={{
                  padding: "4px 8px",
                  borderRadius: "4px",
                  backgroundColor: APP_COLORS.primaryMain,
                  color: "white",
                  cursor: "pointer",
                  transition: "background-color 0.3s, transform 0.2s"
                }}
              >
                <Typography fontSize={12}>Logout</Typography>
                <img
                  src={logoutSVG}
                  alt='logout'
                  style={{ width: 10, height: 10, marginLeft: 3 }}
                />
              </Box>
            </MenuItem>
          </Box>
        ) : null}
      </Menu>
    </Box>
  );
};

export default MobileMenu;
