import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";

interface TermsOfServiceDialogProps {
  openTermsOfService: boolean;
  handleCloseTermsOfService: () => void;
}

export default function TermsOfServiceDialog({
  openTermsOfService,
  handleCloseTermsOfService
}: TermsOfServiceDialogProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Dialog
      fullScreen={fullScreen}
      PaperProps={{
        sx: {
          width: fullScreen
            ? "100%"
            : { xs: "100%", sm: "90%", md: "70%", lg: "50%" },
          maxHeight: "90vh"
        }
      }}
      open={openTermsOfService}
      onClose={handleCloseTermsOfService}
      scroll='paper'
    >
      <DialogTitle>Terms of Service</DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{ overflowX: "hidden", wordBreak: "break-word" }}
        >
          <Typography variant='subtitle1' gutterBottom>
            Last updated: April 12, 2024
          </Typography>
          <Typography paragraph>
            Each organization, including its employees and affiliated
            researchers, accessing and utilizing resources through LabShare
            (&quot;RECIPIENT&quot;) acknowledges and agrees that the materials,
            including but not limited to animal models, tissue biospecimens, and
            related derivatives (&quot;MATERIAL&quot;), provided by donors and
            made available through LabShare, are intended for non-commercial
            biomedical research purposes exclusively. LabShare serves as a
            platform for facilitating the exchange of these materials among
            researchers for the advancement of scientific knowledge and
            discovery. The MATERIAL provided by donors and accessed through
            LabShare is offered as a service to the research community, with the
            understanding that recipients will utilize these resources
            responsibly and ethically in accordance with applicable laws,
            regulations, and institutional policies governing research conduct.
            By accessing and utilizing MATERIAL through LabShare, recipients
            affirm their commitment to utilizing these resources solely for
            non-commercial biomedical research purposes and agree to abide by
            the terms and conditions set forth herein.
          </Typography>
          <Typography
            variant='h6'
            sx={{ textDecoration: "underline", fontWeight: "bold" }}
          >
            Terms of Use
          </Typography>
          <Typography paragraph>
            By accessing and using LabShare (&quot;the Platform&quot;), you
            agree to abide by the following terms and conditions:
          </Typography>
          <ol>
            <li>
              <strong>Non-Commercial Use: </strong>Materials obtained through
              LabShare, including animal models, tissue biospecimens, and other
              resources (&quot;the Materials&quot;), shall be used solely for
              non-commercial biomedical research purposes.
            </li>
            <li>
              <strong>No Use in Human Subjects: </strong>The Materials provided
              by LabShare are not intended for use in human subjects, including
              diagnostic testing.
            </li>
            <li>
              <strong>No Redistribution or Commercial Use: </strong>Recipients
              of Materials obtained through LabShare shall not further
              distribute the Materials to others or use them for fee-for-service
              purposes for a third party without written consent from LabShare.
            </li>
            <li>
              <strong>Modification and Transfer: </strong> If a recipient
              creates a substance that contains or incorporates the Materials
              (&quot;Modification&quot;), they shall not transfer the
              Modification to others without written consent from LabShare.
              Transfers of Modifications for biomedical research purposes
              require separate permission from LabShare.
            </li>
            <li>
              <strong>Acknowledgment: </strong> Recipients agree to acknowledge
              LabShare and the original contributors of the Materials in any
              presentations and publications reporting the use of the Materials.
            </li>
            <li>
              <strong>Experimental Nature: </strong> Materials obtained through
              LabShare are experimental in nature and may possess hazardous
              properties. Recipients assume all liability for claims and damages
              arising from the use, storage, or disposal of the Materials.
            </li>
            <li>
              <strong>No Warranties: </strong> LabShare makes no representations
              or warranties of any kind, either expressed or implied, regarding
              the Materials. Recipients accept all Materials &quot;as is&quot;
              and at their own risk.
            </li>
            <li>
              <strong>Indemnification: </strong>Recipients shall hold harmless,
              defend, and indemnify LabShare and its affiliates against any
              claims, costs, or liabilities arising from the recipient&apos;s
              use, storage, or disposal of the Materials.
            </li>
            <li>
              <strong>Compliance: </strong>Recipients agree to use the Materials
              in compliance with all applicable statutes, regulations, and
              standards for the humane care and use of animals.
            </li>
            <li>
              <strong>Acceptance: </strong>Acceptance of Materials from LabShare
              shall be deemed agreement to these Terms of Use.
            </li>
          </ol>
          <Typography paragraph>
            Please note that these terms are subject to change, and by using
            LabShare, users agree to abide by the most current version of the
            Terms of Use.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseTermsOfService}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
