import { auth } from "@features/Auth/authSlice";
import { registerSlice } from "@features/Auth/registerSlice";
import { contact } from "@features/Contact/contactSlice";
import { publicResourcesSlice } from "@features/ExternalDatabase/externalDatabaseSlice";
import { resourceSlice } from "@features/InsitutionDatabase/resourceSlice";
import { institutionAdminSlice } from "@features/InstitutionAdmin/institutionAdminSlice";
import { labSlice } from "@features/Lab/labslice";
import { messagingSlice } from "@features/Messaging/messagingSlice";
import { notificationsSlice } from "@features/Notifications/notificationSlice";
import { profileSlice } from "@features/Profile/profileSlice";
import { socketSlice } from "@features/sockets/socketSlice";
import { tagSlice } from "@features/Tags/tagSlice";
import { transferSlice } from "@features/Transfer/transferSlice";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { io } from "socket.io-client";
import { REACT_APP_SOCKET_URL } from "src/config";
import { configSlice } from "src/config/configSlice";
import socketIOMiddleware from "src/middlewares/socket-redux";

const persistAuthConfig = {
  key: "auth",
  storage,
  whitelist: ["user"] // the keys we want to persist
};

const persistRegisterConfig = {
  key: "register",
  storage
};

const persistAppConfig = {
  key: "config",
  storage
};

const persistRegisterReducer = persistReducer(
  persistRegisterConfig,
  registerSlice.reducer
);
const persistedAuthReducer = persistReducer(persistAuthConfig, auth.reducer);

const persistConfigReducer = persistReducer(
  persistAppConfig,
  configSlice.reducer
);

export const rootReducer = combineReducers({
  [auth.name]: persistedAuthReducer,
  [transferSlice.name]: transferSlice.reducer,
  [socketSlice.name]: socketSlice.reducer,
  [registerSlice.name]: persistRegisterReducer,
  [profileSlice.name]: profileSlice.reducer,
  [labSlice.name]: labSlice.reducer,
  [tagSlice.name]: tagSlice.reducer,
  [resourceSlice.name]: resourceSlice.reducer,
  [configSlice.name]: persistConfigReducer,
  [contact.name]: contact.reducer,
  [messagingSlice.name]: messagingSlice.reducer,
  [notificationsSlice.name]: notificationsSlice.reducer,
  [institutionAdminSlice.name]: institutionAdminSlice.reducer,
  [publicResourcesSlice.name]: publicResourcesSlice.reducer
});

const socket = io(REACT_APP_SOCKET_URL || "http://localhost:3000");

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    const defaultMiddleware = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          "persist/PERSIST",
          "persist/REHYDRATE",
          "persist/REGISTER"
        ]
      }
    });

    const middlewares = defaultMiddleware.concat(socketIOMiddleware(socket));

    // logger middleware only in development environment
    if (process.env.NODE_ENV === "development") {
      return middlewares.concat(logger);
    }

    return middlewares;
  }
});

export { socket };

export const persistor = persistStore(store);

// Inferred type: { auth: AuthState }
export type AppDispatch = typeof store.dispatch;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
