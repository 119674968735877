import React, { FC, Fragment, ReactNode } from "react";
import { useSelector } from "react-redux";

import { getUser } from "@features/Auth/authSlice";
import { selectLabLoading } from "@features/Lab/labslice";
import User from "@interfaces/user";

import DashboardSidebarDesktop from "./DashboardSidebarDesktop";
import DashboardSidebarMobile from "./DashboardSidebarMobile";

interface DashboardSidebarProps {
  children: ReactNode;
  disableUserCard?: boolean;
  disableSecondaryNavigation?: boolean;
  currentUser?: User;
  hideInstitution?: boolean;
  showSidebar?: boolean;
}

const DashboardSidebar: FC<DashboardSidebarProps> = ({
  children,
  disableUserCard = false,
  disableSecondaryNavigation = false,
  hideInstitution,
  showSidebar
}) => {
  const currentUser = useSelector(getUser);
  const loading = useSelector(selectLabLoading);

  return (
    <Fragment>
      <DashboardSidebarMobile
        disableUserCard={disableUserCard}
        disableSecondaryNavigation={disableSecondaryNavigation}
        currentUser={currentUser}
        loading={loading}
        showSidebar={showSidebar}
      >
        {children}
      </DashboardSidebarMobile>
      <DashboardSidebarDesktop
        currentUser={currentUser}
        loading={loading}
        hideInstitution={hideInstitution}
      >
        {children}
      </DashboardSidebarDesktop>
    </Fragment>
  );
};

export default DashboardSidebar;
