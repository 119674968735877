import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ContactFormDialog from "@components/Dialogs/ContactFormDialog";
import { getUser } from "@features/Auth/authSlice";
import AddResourceDialog from "@features/InsitutionDatabase/components/AddResourceDialog";
import {
  clearSearchResults,
  deleteResource
} from "@features/InsitutionDatabase/resourceSlice";
import clsx from "@features/utils/clsx";
import { constants } from "@features/utils/constants";
import FormatRelativeTime from "@features/utils/formatTime";
import { handleShare } from "@features/utils/handleShare";
import { BASE_S3_URL } from "@features/utils/manage-file";
import { renderStrainName } from "@features/utils/render-strain-name";
import { useSendDirectMessage } from "@hooks/sendDirectMessage";
import { PostType } from "@interfaces/post";
import { ResourceValues } from "@interfaces/resource-values";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Stack,
  Typography
} from "@mui/material";
import DefaultProfile from "@static/icons/default-profile.png";
import globe from "@static/icons/globe.svg";
import university from "@static/icons/portal-uni-Icon.svg";
import share from "@static/icons/share-dark.svg";
import { AppDispatch } from "src/services/store";
import { APP_COLORS } from "src/styles/colors";

import PostTags from "./PostTags";

interface EntryProps {
  key: string;
  post: ResourceValues;
}

export default function Entry({ post }: EntryProps) {
  const imgUrl =
    !post.contact_user_details?.profile_photo_thumbnail_key ||
    post.contact_user_details?.profile_photo_thumbnail_key.length === 0
      ? DefaultProfile
      : `${BASE_S3_URL}${post.contact_user_details.profile_photo_thumbnail_key}`;

  // Set up code for the "actions" menu
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const openActionMenu = Boolean(anchorEl);
  const handleClickActionMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseActionMenu = () => {
    setAnchorEl(null);
  };

  // Set up code for delete confirmation
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const handleClickDelete = () => {
    setOpenDeleteModal(true);
    handleCloseActionMenu();
  };
  const handleDeleteModalClose = () => {
    setOpenDeleteModal(false);
  };
  const dispatch: AppDispatch = useDispatch();

  const handleDeleteRequest = () => {
    const postId = post.id as string;
    dispatch(deleteResource(postId));
    handleDeleteModalClose();
  };

  // Set up code for contact form dialog
  const currentUser = useSelector(getUser);

  const authorLoggedIn = post.contact_user_details?.user_id === currentUser.id;
  const [openContactForm, setOpenContactForm] = React.useState(false);

  const sendDirectMessage = useSendDirectMessage();

  const handleCloseContactForm = () => {
    setOpenContactForm(false);
  };

  const authorDisplayName = `${post.contact_user_details?.first_name || ""} ${
    post.contact_user_details?.last_name || ""
  }`;
  const createdDate = post.created_at as string;

  const backgroundClass =
    post.source === PostType.USER_SHARE
      ? `bg-[#f5dd9d] text-black`
      : `bg-[#ffa098] text-white`;

  // Manage read more / read less state
  const [isTruncated, setIsTruncated] = useState(true);
  const maxLength = 500;

  const handleToggleReadMore = () => {
    setIsTruncated(!isTruncated);
  };

  // Truncate the description if it's too long
  const truncatedDescription =
    post.description && post.description.length > maxLength
      ? post.description.substring(0, maxLength) + "..."
      : post.description;

  // edit post
  const [openAddResourceDialog, setOpenAddResourceDialog] = useState(false);
  const [editingResource, setEditingResource] = useState<ResourceValues | null>(
    null
  );

  const handleOpenAddResourceDialog = (resource: ResourceValues) => {
    setEditingResource({
      ...resource,
      category: resource.category || constants.animalModel,
      strainName: resource.name
    });
    setOpenAddResourceDialog(true);
  };

  const handleCloseAddResourceDialog = () => {
    setOpenAddResourceDialog(false);
    setEditingResource(null);
    dispatch(clearSearchResults());
    handleCloseActionMenu();
  };

  return (
    <>
      <Box>
        <Card variant='outlined' sx={{ borderRadius: "10px" }}>
          <CardHeader
            sx={{ px: 3 }}
            avatar={
              <Avatar
                sx={{
                  bgcolor: "secondary.main",
                  width: "70px",
                  height: "70px",
                  objectFit: "cover",
                  "& .MuiCardHeader-action": {
                    alignSelf: "center",
                    margin: 0
                  }
                }}
                src={imgUrl}
              />
            }
            title={
              <Stack>
                <Link
                  href={`/dashboard/member/${post.contact_user_details?.user_id}`}
                  underline='hover'
                  fontSize={17}
                  color={APP_COLORS.black}
                >
                  <strong>{authorDisplayName}</strong>
                </Link>
                <Typography>
                  {post.contact_user_details?.institution_name}
                </Typography>
              </Stack>
            }
            subheader={
              <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                <Typography fontSize={14}>
                  {FormatRelativeTime(createdDate)}
                </Typography>
                <Typography variant='body2' component='span'>
                  &bull;
                </Typography>

                <Box
                  component={"img"}
                  src={
                    post.public === true || post.public == null
                      ? globe
                      : university
                  }
                  height={18}
                  width={18}
                  sx={{ objectFit: "cover" }}
                  title={
                    post.public === true || post.public == null
                      ? "This post is public and visible to everyone on Labshare."
                      : "This post is visible only within your institution."
                  }
                />
              </Stack>
            }
            action={
              authorLoggedIn ? (
                <Box
                  sx={{ display: "flex", alignItems: "center", height: "100%" }}
                >
                  <IconButton
                    aria-label='settings'
                    onClick={handleClickActionMenu}
                    aria-expanded={openActionMenu ? "true" : undefined}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </Box>
              ) : null
            }
          />
          <Menu
            anchorEl={anchorEl}
            open={openActionMenu}
            onClose={handleCloseActionMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
          >
            <MenuItem onClick={handleClickDelete}>Delete post</MenuItem>
            <MenuItem
              onClick={() =>
                handleOpenAddResourceDialog(post as ResourceValues)
              }
            >
              Edit post
            </MenuItem>
          </Menu>
          <Dialog open={openDeleteModal} onClose={handleDeleteModalClose}>
            <DialogTitle>
              Are you sure you want to delete this post?
            </DialogTitle>
            <DialogContent>
              <Typography>This action cannot be undone.</Typography>
              <DialogActions>
                <Button variant='contained' onClick={handleDeleteRequest}>
                  Confirm
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
          <Divider />
          <CardContent sx={{ py: "10px", px: 3 }}>
            <Stack pl={0.2} spacing={3} mb={2}>
              <Stack spacing={1}>
                {post.title ? (
                  <Typography fontSize={"1.1rem"} fontWeight={500}>
                    {renderStrainName(post.title)}
                  </Typography>
                ) : (
                  <></>
                )}
                <Stack direction={"row"} gap={1}>
                  <div
                    className={clsx(
                      "font-semiBold rounded-full py-1 px-5 text-xs flex items-center justify-center border border-2 border-transparent",
                      backgroundClass
                    )}
                  >
                    {post.source === PostType.USER_SHARE ? "Share" : "Request"}
                  </div>
                  {post.category ? (
                    <div
                      className={clsx(
                        "font-semiBold border border-[#209498] capitalize py-1 px-5 text-xs flex items-center justify-center rounded-full"
                      )}
                    >
                      {post.category.split("_").join(" ")}
                    </div>
                  ) : (
                    <></>
                  )}
                </Stack>
              </Stack>
              {post.description ? (
                <Typography fontSize={"1rem"}>
                  {renderStrainName(
                    isTruncated ? truncatedDescription : post.description
                  )}
                  {post.description.length > maxLength && (
                    <Button onClick={handleToggleReadMore} sx={{ ml: 1 }}>
                      {isTruncated ? "Read more" : "Read less"}
                    </Button>
                  )}
                </Typography>
              ) : (
                <></>
              )}
              <Stack>
                {post.name ? (
                  <Typography component={"small"} fontSize={"0.8rem"}>
                    <strong>
                      {post.category === "cell_line"
                        ? "Cell Line Name: "
                        : post.category === "plasmid"
                          ? "Plasmid Name: "
                          : post.category === "antibody"
                            ? "Antibody Name: "
                            : post.category === "biospecimen"
                              ? "Biospecimen Name: "
                              : post.category === "equipment"
                                ? "Equipment Name: "
                                : post.category === "animal_model"
                                  ? "Strain Name: "
                                  : "Name: "}
                    </strong>
                    {renderStrainName(post.name)}
                  </Typography>
                ) : (
                  <></>
                )}

                {post.species_name ? (
                  <Typography component={"small"} fontSize={"0.8rem"}>
                    <strong>Species: </strong>
                    {post.species_name}
                  </Typography>
                ) : (
                  <></>
                )}
                {post.jax_external_id ? (
                  <Typography component={"small"} fontSize={"0.8rem"}>
                    <strong>JAX ID: </strong>
                    <Link
                      color={APP_COLORS.black}
                      target='_blank'
                      rel='noreferrer'
                      className='underline'
                      href={`https://www.jax.org/strain/${post.jax_external_id}`}
                    >
                      {post.jax_external_id}
                    </Link>
                  </Typography>
                ) : (
                  <></>
                )}
                {post.mgi_external_id ? (
                  <Typography component={"small"} fontSize={"0.8rem"}>
                    <strong>MGI ID: </strong>
                    <Link
                      color={APP_COLORS.black}
                      target='_blank'
                      rel='noreferrer'
                      className='underline'
                      href={`https://www.informatics.jax.org/strain/MGI:${post.mgi_external_id}`}
                    >
                      {post.mgi_external_id}
                    </Link>
                  </Typography>
                ) : (
                  <></>
                )}
                {post.atcc_external_id ? (
                  <Typography component={"small"} fontSize={"0.8rem"}>
                    <strong>ATCC ID: </strong>
                    <Link
                      color={APP_COLORS.black}
                      target='_blank'
                      rel='noreferrer'
                      className='underline'
                      href={`https://www.atcc.org/products/${post.atcc_external_id}`}
                    >
                      {post.atcc_external_id}
                    </Link>
                  </Typography>
                ) : (
                  <></>
                )}
                {post.morphology ? (
                  <Typography component={"small"} fontSize={"0.8rem"}>
                    <strong>Morphology: </strong>
                    {post.morphology}
                  </Typography>
                ) : (
                  <></>
                )}
              </Stack>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 2
                }}
              >
                <PostTags post={post} />
              </Box>
            </Stack>
          </CardContent>
          <Divider />
          <Box
            display='flex'
            justifyContent='flex-end'
            alignItems='flex-end'
            py={2}
            px={3}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: { xs: 1, md: "none" }
              }}
            >
              <Button
                sx={{
                  borderRadius: 12,
                  borderColor: APP_COLORS.buttonGray,
                  color: APP_COLORS.buttonGrayText,
                  "&:hover": {
                    borderColor: APP_COLORS.darkGray,
                    color: APP_COLORS.darkGray
                  }
                }}
                color='primary'
                size='small'
                variant='outlined'
                onClick={() => handleShare("post", post.id as string)}
              >
                <Typography fontSize='0.8rem'>Share</Typography>
                <Box
                  component={"img"}
                  src={share}
                  width={"15px"}
                  height={"15px"}
                  ml={1}
                />
              </Button>
              {!authorLoggedIn ? (
                <Button
                  sx={{
                    borderRadius: 12,
                    borderColor: APP_COLORS.buttonGray,
                    color: APP_COLORS.buttonGrayText,
                    "&:hover": {
                      borderColor: APP_COLORS.darkGray,
                      color: APP_COLORS.darkGray
                    }
                  }}
                  color='primary'
                  size='small'
                  variant='outlined'
                  onClick={() => sendDirectMessage(post.contact_user_details)}
                >
                  <Typography fontSize='0.8rem'>Contact</Typography>
                </Button>
              ) : (
                <></>
              )}
            </Box>
          </Box>
        </Card>
        <ContactFormDialog
          openContactForm={openContactForm}
          handleCloseContactForm={handleCloseContactForm}
          postId={post.id}
          toUserId={post.contact_user_details?.user_id}
          authorDisplayName={authorDisplayName}
        />
      </Box>
      <AddResourceDialog
        open={openAddResourceDialog}
        handleClose={handleCloseAddResourceDialog}
        initialValues={editingResource || undefined}
        isInstitution={false}
        editing={!!editingResource}
        postEdit
      />
    </>
  );
}
