import getError from "@features/utils/get-error";
import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import api, { cancelToken } from "src/services/api";
import { RootState } from "src/services/store";

interface PublicResource {
  contact_email: string;
  id: string;
  name: string;
  category: string;
  description: string;
}

interface PublicResourcesState {
  resources: PublicResource[];
  loading: boolean;
  error: string | null;
  metadata: {
    page: number;
    limit: number;
    total_count: number;
    total_pages: number;
  };
}

const initialState: PublicResourcesState = {
  resources: [],
  loading: false,
  error: null,
  metadata: {
    page: 1,
    limit: 5,
    total_count: 0,
    total_pages: 0
  }
};

export const fetchPublicResources = createAsyncThunk(
  "resources/fetchPublicResources",
  async (
    {
      params
    }: {
      params: {
        page?: number;
        limit?: number;
        filters?: string;
        search?: string;
      };
    },
    { signal, rejectWithValue }
  ) => {
    const source = cancelToken.source();
    signal.addEventListener("abort", () => source.cancel());

    try {
      const response = await api.get(`/resources/public`, {
        params: {
          page: params.page || 1,
          limit: params.limit || 5,
          ...params
        },
        cancelToken: source.token
      });

      return {
        resources: response.data.data.public_resources,
        metadata: response.data.data.metadata
      };
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

interface SuggestBiobankValues {
  biobank_name: string;
  biobank_resource_link: string;
}

export const suggestBioBank = createAsyncThunk(
  "resources/suggestBioBank",
  async (biobankData: SuggestBiobankValues, { rejectWithValue }) => {
    const cleanData = {
      biobank_name: biobankData.biobank_name,
      biobank_resource_link: biobankData.biobank_resource_link
    };

    try {
      const response = await api.post(`/resources/suggest_biobank`, cleanData);
      enqueueSnackbar("We have received your suggestion. Thank you.", {
        variant: "success"
      });
      return response.data;
    } catch (err) {
      enqueueSnackbar("Failed to suggest biobank", { variant: "warning" });
      return rejectWithValue(getError(err));
    }
  }
);

export const publicResourcesSlice = createSlice({
  name: "publicResources",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPublicResources.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPublicResources.fulfilled, (state, action) => {
        state.loading = false;
        state.resources = action.payload.resources;
        state.metadata = action.payload.metadata;
      })
      .addCase(fetchPublicResources.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  }
});

const getSlice = (state: RootState) => state[publicResourcesSlice.name];

export const selectResources = createSelector(
  getSlice,
  (slice) => slice.resources
);

export const selectLoading = createSelector(getSlice, (slice) => slice.loading);

export const selectError = createSelector(getSlice, (slice) => slice.error);

export const selectPaginationMetadata = createSelector(
  getSlice,
  (slice) => slice.metadata
);
