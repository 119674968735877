import React from "react";

import { Add } from "@mui/icons-material";
import { Button, Card, CardContent, Stack, Typography } from "@mui/material";

interface DatabaseHeaderProps {
  onSuggestBioBank: () => void;
}

const DatabaseHeader: React.FC<DatabaseHeaderProps> = ({
  onSuggestBioBank
}) => {
  return (
    <Card variant='outlined' sx={{ borderRadius: "10px", marginBottom: 2 }}>
      <CardContent sx={{ px: 4 }}>
        <Stack spacing={1} alignItems='center' justifyContent='center'>
          <Typography fontWeight='bold' fontSize='1.2rem' textAlign='center'>
            Consolidated Biorepository & Resource Database
          </Typography>
          <Typography textAlign='center'>
            Search for publicly available resources (animal models, cell lines,
            biospecimens) from around the world
          </Typography>
          <Button
            variant='outlined'
            sx={{ width: "180px", borderRadius: "50px", border: 1 }}
            onClick={onSuggestBioBank}
          >
            <Add sx={{ mr: 1 }} />
            <Typography>Suggest BioBank</Typography>
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default DatabaseHeader;
