declare global {
  interface Window {
    __APP_CONFIG__: {
      REACT_APP_SOCKET_URL: string;
      S3_BUCKET_NAME: string;
      ENABLE_METRICS_FEATURE: boolean;
      ENABLE_PUBLIC_DATABASE_FEATURE: boolean;
      ENABLE_MESSAGING_FEATURE: boolean;
    };
  }
}

export const getConfig = () => {
  if (!window.__APP_CONFIG__) {
    throw new Error("Config not loaded");
  }
  return window.__APP_CONFIG__;
};

export const REACT_APP_SOCKET_URL = getConfig().REACT_APP_SOCKET_URL;
export const S3_BUCKET_NAME = getConfig().S3_BUCKET_NAME;
export const ENABLE_METRICS_FEATURE = getConfig().ENABLE_METRICS_FEATURE;
export const ENABLE_PUBLIC_DATABASE_FEATURE =
  getConfig().ENABLE_PUBLIC_DATABASE_FEATURE;
export const ENABLE_MESSAGING_FEATURE = getConfig().ENABLE_MESSAGING_FEATURE;
