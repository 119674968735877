import React from "react";
import { useSelector } from "react-redux";

import { selectUnreadCount } from "@features/Notifications/notificationSlice";
import DashboardNotifications from "src/layout/dashboard/DashboardNotifications";

const NotificationsCard = () => {
  const unreadNotificationCount = useSelector(selectUnreadCount);

  return (
    <>
      <DashboardNotifications />
      {unreadNotificationCount > 0 && (
        <span className='absolute top-1 right-7 -mt-1 -mr-2 flex h-4 w-4 items-center justify-center rounded-full bg-red-500 text-white text-xs'>
          {unreadNotificationCount}
        </span>
      )}
    </>
  );
};

export default NotificationsCard;
