import { NavigateFunction } from "react-router-dom";

import { MultiPageFormProps } from "@hooks/useFormPage";
import { FormikHelpers } from "formik";
import { AppDispatch } from "src/services/store";

import { ResourceProperties } from "./resource-values";
import User from "./user";

export interface BaseFormValues {
  principal_investigator: string;
  protocol_number: string;
  donor_email: string;
  donor_building: string;
  donor_room: string;
  cage_card_number: string;
  sex: string;
  age_in_weeks: number;
  strain: string;
  species: string;
  // eslint-disable-next-line
  resource_data?: Record<string, any>;
  category: string;
  status: string;
}

export interface StartTransferFormValues extends BaseFormValues {
  category: string;
  quantity?: number;
  estimated_market_value_per_mouse: number;
  donor_full_name: string;
  donor_phone_number: string;
  receiver_full_name: string;
  receiver_email: string;
  receiver_phone_number: string;
  receiver_protocol_number: string;
  receiver_building: string;
  donor_room: string;
  receiver_room: string;
  receiver_principal_investigator: string;
  // eslint-disable-next-line
  resource_data: Record<string, any>;
  donor_user_id?: string;
  receiver_user_id?: string;
}

export interface TransferSourceFieldValues {
  source: string;
}

export const initialStartResourceFormTransferValues = {
  age_in_weeks: 0,
  principal_investigator: "",
  protocol_number: "",
  donor_email: "",
  donor_building: "",
  donor_room: "",
  cage_card_number: "",
  sex: "male",
  resource_data: {},
  strain: "",
  species: "",
  category: "",
  status: "",
  quantity: 0,
  estimated_market_value_per_mouse: 0,
  donor_full_name: "",
  donor_phone_number: "",
  receiver_full_name: "",
  receiver_email: "",
  receiver_phone_number: "",
  receiver_protocol_number: "",
  receiver_building: "",
  receiver_room: "",
  receiver_principal_investigator: ""
};

export enum TransferType {
  Donate = "donate",
  ResourceTransfer = "resourceTransfer"
}

export interface DonateFormValues {
  // eslint-disable-next-line
  resource_data?: Record<string, any>;
  principal_investigator: string;
  protocol_number: string;
  donor_email: string;
  donor_building: string;
  strain: string;
  donor_comment?: string;
  age_in_weeks: number;
  sex: string;
  donor_room: string;
  cage_card_number: string;
  species: string;
  number_of_mice: number;
}

export const initialDonateValues = {
  principal_investigator: "",
  protocol_number: "",
  donor_email: "",
  donor_building: "",
  donor_room: "",
  cage_card_number: "",
  strain: "",
  species: "",
  number_of_mice: 0,
  age_in_weeks: 0,
  sex: "male",
  donor_comment: "",
  resource_data: {}
};

export type ParticipantFieldsValues = Pick<
  StartTransferFormValues,
  | "donor_full_name"
  | "receiver_full_name"
  | "principal_investigator"
  | "receiver_principal_investigator"
  | "donor_email"
  | "receiver_email"
  | "donor_phone_number"
  | "receiver_phone_number"
  | "protocol_number"
  | "receiver_protocol_number"
  | "cage_card_number"
  | "donor_room"
  | "receiver_room"
  | "donor_building"
  | "receiver_building"
>;

export type TransferFieldsValues = Pick<
  StartTransferFormValues,
  "quantity" | "sex" | "age_in_weeks" | "estimated_market_value_per_mouse"
>;

export type TransferFormProps = MultiPageFormProps;
export type StartResourceTransferProps = MultiPageFormProps;

export const requiredStartTransferFields = [
  "quantity",
  "age_in_weeks",
  "estimated_market_value_per_mouse"
] as const;

interface ResourceData {
  category?: string;
  source?: string;
  resourcePropsValues?: ResourceProperties;
  strainName?: string;
  mutation_type_names?: string[];
  target_gene_names?: string[];
  species_name?: string;
  // eslint-disable-next-line
  [key: string]: any;
}
export interface HandleTransferSubmitParams {
  values: StartTransferFormValues;
  user: User;
  dispatch: AppDispatch;
  resourceData: ResourceData;
  formik: FormikHelpers<StartTransferFormValues>;
  navigate: NavigateFunction;
  previousPage: () => void;
}
