/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { getUser } from "@features/Auth/authSlice";
import { selectInstitutionById } from "@features/Lab/labslice";
import PendingApprovalPage from "@pages/InstitutionalPortal/PendingApprovalPage";
import NonPartnerInstitutionPage from "@pages/NonPartnerInstitutionPage";

interface ProtectedInstitutionalRouteProps {
  component: React.ComponentType<any>;
  [key: string]: any;
}

const ProtectedInstitutionalRoute: React.FC<
  ProtectedInstitutionalRouteProps
> = ({ component: Component, ...rest }) => {
  const currentUser = useSelector(getUser);
  const institution = useSelector(selectInstitutionById);

  // if user is logged in
  if (!currentUser.id) {
    return <Navigate to='/login' />;
  }

  // Checking if institution is partner and user is approved
  const isPartner = institution.is_partner;
  const isApproved = currentUser?.institutionStatus === "approved";

  if (!isPartner) {
    return <NonPartnerInstitutionPage showSidebar={true} />;
  }

  if (!isApproved) {
    return <PendingApprovalPage />;
  }

  return <Component {...rest} />;
};

export default ProtectedInstitutionalRoute;
