import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { requestAccessToInstitutionFeatures } from "@features/Lab/labslice";
import {
  Box,
  Button,
  Card,
  CardContent,
  Link,
  Typography
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import backgroundImg from "@static/instDatabase.png";
import { AppDispatch } from "src/services/store";

import ContactUs from "./Landing/components/ContactUs";

interface NonPartnerInstitutionPageProps {
  showSidebar: boolean;
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#009688"
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "4px"
        }
      }
    }
  }
});

export default function NonPartnerInstitutionPage({
  showSidebar
}: NonPartnerInstitutionPageProps) {
  const dispatch: AppDispatch = useDispatch();
  const [showContactForm, setShowContactForm] = useState(false);

  const handleRequestAccess = () => {
    dispatch(requestAccessToInstitutionFeatures());
  };

  const handleContactUsClick = () => {
    setShowContactForm(true);
  };
  return (
    <ThemeProvider theme={theme}>
      {showContactForm ? (
        <ContactUs />
      ) : (
        <Box
          sx={{
            position: "relative",
            width: "100vw",
            height: "100vh",
            overflow: "hidden"
          }}
        >
          <img
            src={backgroundImg}
            alt='Background'
            style={{
              width: "80%",
              height: "100%",
              objectFit: "cover",
              filter: "blur(8px)"
            }}
          />
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: showSidebar ? 0 : { xs: "42%", md: "50%" },
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: 2,
              transform: showSidebar
                ? "translateX(-150px)"
                : "translateX(-50%)",
              transition: "left 0.5s ease, transform 0.5s ease"
            }}
          >
            <Card
              sx={{
                maxWidth: { xs: 250, sm: 400 },
                width: "100%",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)"
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 3
                  }}
                >
                  <Typography
                    variant='h6'
                    component='h2'
                    align='center'
                    sx={{ fontWeight: "bold" }}
                  >
                    Your institution is not registered on LabShare
                  </Typography>
                  <Button
                    variant='contained'
                    color='primary'
                    fullWidth
                    onClick={handleRequestAccess}
                    sx={{
                      mt: 1,
                      py: 1.5,
                      maxWidth: 300
                    }}
                  >
                    Request Access
                  </Button>
                  <Typography variant='body2' align='left'>
                    We&apos;ll reach out to your admin to activate your
                    institutional portal. If you&apos;re a research admin,
                    please{" "}
                    <Link
                      href='#contact'
                      color='primary'
                      underline='hover'
                      onClick={handleContactUsClick}
                    >
                      contact us
                    </Link>
                    .
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Box>
      )}
    </ThemeProvider>
  );
}
