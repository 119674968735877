export const constants = {
  animalModel: "animal_model",
  animal_models: "animal_models",
  cell_line: "cell_line",
  cellLine: "cell_line",
  biospecimen: "biospecimen",
  antibody: "antibody",
  plasmid: "plasmid",
  equipment: "equipment",
  other: "other",
  institutionalDBSources:
    "user_post_share,lab_member_upload,super_admin_upload",
  lab_sources: "lab_member_upload,super_admin_upload",
  homeResource: "user_post_share,user_post_request",
  labUpload: "lab_member_upload",
  home: "home",
  profile: "profile",
  institution: "institution",
  jax: "jax",
  mgi: "mgi",
  manual_entry: "manual_entry",
  privateChat: "private",
  groupChat: "group",
  transfer: "transfer",
  user_to_user: "user_to_user",
  action_required: "action_required"
};

export const columns = [
  { id: "category", label: "Category" },
  { id: "source", label: "Source" },
  { id: "name", label: "Biobank Name" },
  { id: "number_of_participants", label: "Number of Participants" },
  {
    id: "number_of_specimens_available",
    label: "Number of Specimens Available"
  },
  { id: "number_of_specimens", label: "Number of Specimens" },
  { id: "age_of_participants", label: "Age of Participants" },
  { id: "biospecimen_types", label: "Biospecimen Types" },
  { id: "sharing_restriction", label: "Sharing Restriction" },
  { id: "description", label: "Description" },
  { id: "research_applications", label: "Research Applications" },
  {
    id: "period_of_specimen_collection",
    label: "Period of Specimen Collection"
  },
  { id: "subject_age", label: "Subject Age" },
  { id: "sex", label: "Sex" },
  { id: "race", label: "Race" },
  { id: "ethnicity", label: "Ethnicity" },
  { id: "clinical_diagnosis", label: "Clinical Diagnosis" },
  { id: "genetic_diagnosis", label: "Genetic Diagnosis" },
  { id: "mutation_types", label: "Mutation Types" },
  { id: "target_genes", label: "Target Genes" },
  { id: "disease", label: "Disease" },
  { id: "storage_condition", label: "Storage Condition" },
  { id: "donor_name", label: "Donor Name" },
  { id: "contact_email", label: "Contact Email" },
  { id: "donor_institution", label: "Donor Institution" },
  { id: "request_link", label: "Request Link" }
];

export const RESEND_TIMEOUT = 50;
