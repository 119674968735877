import React, { FC, ReactNode } from "react";

import clsx from "@features/utils/clsx";

interface TableBodyProps {
  children: ReactNode;
  className?: string;
}

const TableBody: FC<TableBodyProps> = ({ children, className }) => (
  <tbody className={clsx("divide-y divide-gray-200", className)}>
    {children}
  </tbody>
);

export default TableBody;
