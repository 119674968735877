/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  emitSocketEvent,
  setupSocketHandlers
} from "@features/sockets/socket-events";
import { Middleware } from "@reduxjs/toolkit";
import { Socket } from "socket.io-client";

// The structure of `meta.socket`
interface SocketMeta {
  channel: string;
  namespace?: string;
  room?: string;
}

// The structure of `action.meta`
interface SocketAction {
  type: string;
  meta?: {
    socket?: SocketMeta;
  };
  [key: string]: any;
}

// Type guard to check if an action is a SocketAction
function isSocketAction(action: unknown): action is SocketAction {
  return (
    typeof action === "object" &&
    action !== null &&
    "type" in action &&
    "meta" in action &&
    typeof (action as any).meta === "object" &&
    (action as any).meta !== null &&
    "socket" in (action as any).meta
  );
}

// Middleware definition
const socketIOMiddleware =
  (socket: Socket): Middleware =>
  (store) => {
    // Setup socket event handlers when middleware is initialized
    setupSocketHandlers(socket, store.dispatch);
    return (next) => (action: unknown) => {
      if (isSocketAction(action) && action.meta?.socket?.channel) {
        const { room, channel } = action.meta.socket;

        if (room) {
          // Emitting 'join_room' event to add the client to the room on the server
          emitSocketEvent(socket, "join_room", room);
        } else {
          // Emitting to the channel if no room is specified
          emitSocketEvent(socket, channel, action);
        }
      }
      // Passing the action to the next middleware or reducer
      return next(action);
    };
  };
export default socketIOMiddleware;
