import React from "react";

import { TransferSourceFieldValues } from "@interfaces/transfer";
import { Button, Stack, Typography } from "@mui/material";
import { FormikProps } from "formik";

interface TransferSourceFieldsProps {
  formik: FormikProps<TransferSourceFieldValues>;
}
export const TransferSourceFields: React.FC<TransferSourceFieldsProps> = ({
  formik
}) => {
  return (
    <>
      <Stack
        direction={"row"}
        alignContent={"center"}
        justifyContent={"left"}
        gap={2}
      >
        <Typography variant='subtitle1' textAlign='left' mb={0}>
          I am<sup className='text-red-500 font-bold'>*</sup>
        </Typography>
        <Button
          sx={{
            borderRadius: 2,
            ...(formik.errors.source ? { borderColor: "#f44336" } : {})
          }}
          variant={
            formik.values.source === "donation"
              ? "shareContained"
              : "shareOutlined"
          }
          onClick={() => formik.setFieldValue("source", "donation")}
        >
          Donating
        </Button>
        <Button
          sx={{
            borderRadius: 2,
            ...(formik.errors.source ? { borderColor: "#f44336" } : {})
          }}
          variant={
            formik.values.source === "receipt"
              ? "requestContained"
              : "requestOutlined"
          }
          onClick={() => formik.setFieldValue("source", "receipt")}
        >
          Receiving
        </Button>
      </Stack>
      {formik.errors.source && (
        <Typography
          component='small'
          ml={"14px"}
          mt={"3px"}
          lineHeight={1.66}
          fontSize={"0.75rem"}
          color='error'
        >
          {formik.errors.source}
        </Typography>
      )}
    </>
  );
};
