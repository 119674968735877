function formatResultsTypeNames(
  mutationTypeNames: string[] | undefined | null
): string {
  if (!mutationTypeNames || !Array.isArray(mutationTypeNames)) {
    return "";
  }

  const filteredArray = mutationTypeNames
    .filter(
      (item): item is string => typeof item === "string" && item.trim() !== ""
    )
    .map((item) => item.trim());

  return filteredArray.join(", ");
}

export default formatResultsTypeNames;
