export type MetricType =
  | "users"
  | "resourcesShared"
  | "resourcesRequested"
  | "resourcesTransferred"
  | "animalsSaved"
  | "researchMoneySaved"
  | "timeToExperiimentSaved";

export enum MetricGridType {
  Users = "users",
  ResourcesShared = "resourcesShared",
  ResourcesRequested = "resourcesRequested",
  ResourcesTransferred = "resourcesTransferred",
  AnimalsSaved = "animalsSaved",
  ResearchMoneySaved = "researchMoneySaved",
  TimeToExperiimentSaved = "timeToExperiimentSaved"
}

export type MetricPropsMap = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [K in MetricType]: any;
};
