/* eslint-disable sort-imports, import/order */
import React from "react";
import { Box } from "@mui/material";
import { DashboardContent } from "src/layout/dashboard";
import ExternalDatabaseTable from "./components/ExternalDatabaseTable";

export default function ExternalDatabasePage() {
  return (
    <DashboardContent>
      <Box>
        <ExternalDatabaseTable />
      </Box>
    </DashboardContent>
  );
}
