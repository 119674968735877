import getError from "@features/utils/get-error";
import { Room } from "@interfaces/messaging";
import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import api, { cancelToken } from "src/services/api";
import { RootState } from "src/services/store";

interface MessagingState {
  rooms: never[];
  messages: [];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  isTyping: boolean;
}

const initialState: MessagingState = {
  messages: [],
  status: "idle",
  error: null,
  isTyping: false,
  rooms: []
};

export const getRoomMessages = createAsyncThunk(
  "messaging/getRoomMessages",
  async (
    params: {
      roomId: string | null;
    },
    { rejectWithValue, signal }
  ) => {
    const source = cancelToken.source();

    signal.addEventListener("abort", () => source.cancel());

    try {
      const { roomId } = params;

      const response = await api.get(`/chat/messages/${roomId}`, {
        cancelToken: source.token
      });
      return {
        roomMessages: response.data?.data?.messages ?? []
      };
    } catch (error) {
      const generatedError = getError(error);
      enqueueSnackbar(generatedError.error.message, { variant: "warning" });
      return rejectWithValue(generatedError);
    }
  }
);

export const markMessagesAsRead = createAsyncThunk(
  "messaging/markMessagesAsRead",
  async (
    params: {
      readerId: string;
      roomId: string | null | undefined;
    },
    { rejectWithValue, signal }
  ) => {
    const source = cancelToken.source();

    signal.addEventListener("abort", () => source.cancel());

    try {
      const { readerId, roomId } = params;

      await api.patch(`/chat/messages/mark_as_unread/${readerId}/${roomId}`, {
        cancelToken: source.token
      });
      return {
        roomId,
        markedAsRead: true
      };
    } catch (error) {
      const generatedError = getError(error);
      enqueueSnackbar(generatedError.error.message, { variant: "warning" });
      return rejectWithValue(generatedError);
    }
  }
);

export const getParticipantRooms = createAsyncThunk(
  "messaging/getParticipantRooms",
  async (
    params: {
      participantId: string;
    },
    { rejectWithValue, signal }
  ) => {
    const source = cancelToken.source();

    signal.addEventListener("abort", () => source.cancel());

    try {
      const { participantId } = params;

      const response = await api.get(`/chat/rooms/${participantId}`, {
        cancelToken: source.token
      });
      return {
        rooms: response.data?.data?.rooms ?? []
      };
    } catch (error) {
      const generatedError = getError(error);
      return rejectWithValue(generatedError);
    }
  }
);

export const messagingSlice = createSlice({
  name: "messaging",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRoomMessages.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getRoomMessages.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.messages = action.payload.roomMessages;
      })
      .addCase(getRoomMessages.rejected, (state) => {
        state.status = "succeeded";
      })
      .addCase(getParticipantRooms.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getParticipantRooms.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.rooms = action.payload.rooms;
      })
      .addCase(getParticipantRooms.rejected, (state) => {
        state.status = "succeeded";
      })
      .addCase(markMessagesAsRead.pending, (state) => {
        state.error = null;
      })
      .addCase(markMessagesAsRead.fulfilled, (state) => {
        state.status = "loading";
      })
      .addCase(markMessagesAsRead.rejected, (state, action) => {
        state.error = action.payload as string;
      });
  }
});

export const { actions, reducer } = messagingSlice;
export default messagingSlice.reducer;
const getMessagingSlice = (state: RootState) => state.messaging;

export const selectRoomMessages = createSelector(
  getMessagingSlice,
  (messagingSlice) => messagingSlice.messages || []
);

export const selectParticipantRooms = createSelector(
  getMessagingSlice,
  (messagingSlice): Room[] => messagingSlice.rooms || []
);
