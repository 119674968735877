export const APP_COLORS = {
  primaryMain: "#209498",
  primaryLight: "#6cccbd",
  secondaryMain: "#a6afb7",
  secondaryLight: "#d2d7db",
  infoMain: "#2196f3",
  customMain: "#fafafa",
  backgroundDefault: "#fafafa",
  backgroundLight: "#FBFBFB",
  lightGray: "#DFE3E7",
  mediumGray: "#D4D4D4",
  darkGray: "#626262",
  mediumLightGray: "#C3C3C3",
  white: "#fff",
  buttonGray: "#D8D8D8",
  buttonGrayText: "#7F7F7F",
  extraLightGray: "#f5f5f5",
  gray: "#F9F9F9",
  black: "#000000",
  lightGreen: "#209498",
  formBorderColor: "#e5e7eb",
  subtitleColor: "#344054",
  iconColor: "#D8D8D8",
  requestColor: "#FF6F61",
  requestColorHover: "#e66457",
  red: "#d32f2f",
  shareColor: "#f5dd9d",
  shareColorHover: "#ddc78d",
  leaveLabColor: "#FF6F61",
  leaveLabColorHover: "#ffe2df",
  metricsBorderColor: "#E4E7EC",
  metricsTextColor: "#475367"
};
