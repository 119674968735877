import React from "react";

import {
  Box,
  Button,
  Modal,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { useFormik } from "formik";
import { APP_COLORS } from "src/styles/colors";

interface SuggestBiobankModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: {
    biobank_name: string;
    biobank_resource_link: string;
  }) => Promise<void>;
}

const SuggestBiobanModal: React.FC<SuggestBiobankModalProps> = ({
  open,
  onClose,
  onSubmit
}) => {
  const formik = useFormik({
    initialValues: {
      biobank_name: "",
      biobank_resource_link: ""
    },
    onSubmit: async (values, { resetForm }) => {
      await onSubmit(values);
      resetForm();
      onClose();
    }
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          backgroundColor: "white",
          borderRadius: "8px",
          boxShadow: 24,
          p: 4
        }}
      >
        <Typography
          id='modal-title'
          variant='h6'
          component='h2'
          sx={{ marginBottom: "16px", fontWeight: "bold" }}
        >
          Suggest a BioBank
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={2}>
            <TextField
              label='Biobank Name'
              variant='outlined'
              fullWidth
              name='biobank_name'
              value={formik.values.biobank_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.biobank_name &&
                Boolean(formik.errors.biobank_name)
              }
              helperText={
                formik.touched.biobank_name && formik.errors.biobank_name
              }
            />

            <TextField
              label='Biobank Resource Link'
              variant='outlined'
              fullWidth
              type='url'
              name='biobank_resource_link'
              value={formik.values.biobank_resource_link}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.biobank_resource_link &&
                Boolean(formik.errors.biobank_resource_link)
              }
              helperText={
                formik.touched.biobank_resource_link &&
                formik.errors.biobank_resource_link
              }
            />

            <Button
              type='submit'
              variant='contained'
              sx={{
                backgroundColor: APP_COLORS.primaryMain
              }}
            >
              Submit
            </Button>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
};

export default SuggestBiobanModal;
