import React from "react";

import { clsx } from "@features/utils/clsx";
import {
  ChevronLeft,
  ChevronRight,
  FirstPage,
  LastPage
} from "@mui/icons-material";

interface PaginationProps {
  className?: string;
  total: number;
  page: number;
  totalPages: number;
  loading: boolean;
  setPage: (page: number) => unknown;
}

const Pagination: React.FC<PaginationProps> = ({
  className = "",
  total,
  totalPages,
  page,
  setPage,
  loading
}) => {
  // Input validation
  const validatedPage = Math.max(1, Math.min(page, Math.max(1, totalPages)));
  const validatedTotalPages = Math.max(1, totalPages);

  // Memoized page numbers calculation
  const pageNumbers = React.useMemo(() => {
    const pagesToShow = 5;
    let startPage = 1;
    let endPage = validatedTotalPages;

    if (validatedTotalPages <= pagesToShow) {
      // Show all pages if total pages are less than or equal to pagesToShow
      startPage = 1;
      endPage = validatedTotalPages;
    } else {
      // Calculate start and end pages ensuring we don't go below 1 or above totalPages
      if (validatedPage <= 3) {
        startPage = 1;
        endPage = Math.min(pagesToShow, validatedTotalPages);
      } else if (validatedPage + 2 >= validatedTotalPages) {
        startPage = Math.max(1, validatedTotalPages - pagesToShow + 1);
        endPage = validatedTotalPages;
      } else {
        startPage = validatedPage - 2;
        endPage = validatedPage + 2;
      }
    }

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i
    );
  }, [validatedTotalPages, validatedPage]);

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= validatedTotalPages && !loading) {
      setPage(newPage);
    }
  };

  return (
    <nav
      className={clsx(
        "bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6",
        className
      )}
      aria-label='Pagination'
    >
      <div className='flex-1 flex justify-center sm:justify-center gap-3 flex-wrap'>
        <button
          onClick={() => handlePageChange(1)}
          disabled={total === 0 || validatedPage === 1 || loading}
          className={`w-5 h-5 border rounded flex items-center justify-center p-3
            ${
              total === 0 || validatedPage === 1
                ? "text-gray-300 bg-white"
                : "bg-gray-100 text-gray-400 hover:border-teal-500"
            }
            ${loading ? "opacity-50 cursor-not-allowed" : ""}`}
          aria-label='First page'
          aria-disabled={total === 0 || validatedPage === 1 || loading}
        >
          <FirstPage className='h-4 w-4' />
        </button>

        <button
          onClick={() => handlePageChange(validatedPage - 1)}
          disabled={total === 0 || validatedPage === 1 || loading}
          className={`w-5 h-5 border rounded flex items-center justify-center p-3
            ${
              total === 0 || validatedPage === 1
                ? "text-gray-300 bg-white"
                : "bg-gray-100 text-gray-400 hover:border-teal-500"
            }
            ${loading ? "opacity-50 cursor-not-allowed" : ""}`}
          aria-label='Previous page'
          aria-disabled={total === 0 || validatedPage === 1 || loading}
        >
          <ChevronLeft className='h-4 w-4' />
        </button>

        {pageNumbers.map((pageNum) => (
          <button
            key={pageNum}
            onClick={() => handlePageChange(pageNum)}
            disabled={pageNum === validatedPage || loading}
            className={`w-5 h-5 border rounded flex items-center justify-center p-3
              ${
                pageNum === validatedPage
                  ? "border-gray-500 bg-gray-50"
                  : "hover:border-teal-500"
              }
              ${loading ? "opacity-50 cursor-not-allowed" : ""}`}
            aria-label={`Page ${pageNum}`}
            aria-current={pageNum === validatedPage ? "page" : undefined}
            aria-disabled={pageNum === validatedPage || loading}
          >
            {pageNum}
          </button>
        ))}

        <button
          onClick={() => handlePageChange(validatedPage + 1)}
          disabled={
            total === 0 || validatedPage === validatedTotalPages || loading
          }
          className={`w-5 h-5 border rounded flex items-center justify-center p-3
            ${
              total === 0 || validatedPage === validatedTotalPages
                ? "text-gray-300 bg-white"
                : "bg-gray-100 text-gray-400 hover:border-teal-500"
            }
            ${loading ? "opacity-50 cursor-not-allowed" : ""}`}
          aria-label='Next page'
          data-testid='paginate-next'
          aria-disabled={
            total === 0 || validatedPage === validatedTotalPages || loading
          }
        >
          <ChevronRight className='h-4 w-4' />
        </button>

        <button
          onClick={() => handlePageChange(validatedTotalPages)}
          disabled={
            total === 0 || validatedPage === validatedTotalPages || loading
          }
          className={`w-5 h-5 border rounded flex items-center justify-center p-3
            ${
              total === 0 || validatedPage === validatedTotalPages
                ? "text-gray-300 bg-white"
                : "bg-gray-100 text-gray-400 hover:border-teal-500"
            }
            ${loading ? "opacity-50 cursor-not-allowed" : ""}`}
          aria-label='Last page'
          data-testid='paginate-last'
          aria-disabled={
            total === 0 || validatedPage === validatedTotalPages || loading
          }
        >
          <LastPage className='h-4 w-4' />
        </button>
      </div>
    </nav>
  );
};

export default Pagination;
