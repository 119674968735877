import React, { FC, ReactNode } from "react";

import clsx from "@features/utils/clsx";

interface TableRowProps {
  nowrap?: boolean;
  children: ReactNode;
  className?: string;
}

const TableBodyRow: FC<TableRowProps> = ({ nowrap, children, className }) => (
  <tr
    className={clsx(
      "table-row",
      {
        "whitespace-nowrap": nowrap
      },
      className
    )}
  >
    {children}
  </tr>
);

export default TableBodyRow;
