import React, { FC, ReactNode } from "react";

import TSAppBar from "@components/AppBar/TSAppBar";
import clsx from "@features/utils/clsx";
import NonPartnerInstitutionPage from "@pages/NonPartnerInstitutionPage";

interface DashboardBodyProps {
  children: ReactNode;
  className?: string;
  restrictAccess?: boolean | undefined;
  showSidebar: boolean;
}

const DashboardBody: FC<DashboardBodyProps> = ({
  children,
  className,
  restrictAccess,
  showSidebar
}) => {
  return (
    <div
      className={clsx(
        "flex flex-col w-0 flex-1 overflow-hidden bg-gray-100",
        className
      )}
    >
      <TSAppBar inDashboard />
      {restrictAccess === true ? (
        <NonPartnerInstitutionPage showSidebar={showSidebar} />
      ) : (
        children
      )}
    </div>
  );
};

export default DashboardBody;
