import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getUser } from "@features/Auth/authSlice";
import { socketCreateRoom } from "@features/sockets/socket-events";
import { constants } from "@features/utils/constants";
import { ResourceUser } from "@interfaces/user";
import { AppDispatch } from "src/services/store";

export const useSendDirectMessage = () => {
  const dispatch: AppDispatch = useDispatch();
  const currentUser = useSelector(getUser);
  const navigate = useNavigate();

  return (participant: ResourceUser | undefined) => {
    dispatch(
      socketCreateRoom({
        room_type: constants.privateChat,
        creator_id: currentUser.id,
        participant_id: participant?.user_id
      })
    );
    navigate(`/dashboard/messaging`, { state: { newChatUser: participant } });
  };
};
