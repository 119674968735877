import React from "react";

import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import backgroundImg from "@static/instDatabase.png";

const theme = createTheme({
  palette: {
    primary: {
      main: "#009688"
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "4px"
        }
      }
    }
  }
});

export default function PendingApprovalPage() {
  const handleContactSupport = () => {
    window.location.href = "mailto:info@labshareapp.com";
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          position: "relative",
          width: "100vw",
          height: "100vh",
          overflow: "hidden"
        }}
      >
        <img
          src={backgroundImg}
          alt='Background'
          style={{
            width: "80%",
            height: "100%",
            objectFit: "cover",
            filter: "blur(8px)"
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 2,
            transform: "translateX(-150px)"
          }}
        >
          <Card
            sx={{
              maxWidth: 400,
              width: "100%",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)"
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 3
                }}
              >
                <Typography
                  variant='h6'
                  component='h2'
                  align='center'
                  sx={{ fontWeight: "bold" }}
                >
                  Account Pending Approval
                </Typography>
                <Typography variant='body1' align='center'>
                  Your account is pending approval from your institution
                  administrator. Please try again later or contact support if
                  your request has not been approved within 48 hours.
                </Typography>
                <Button
                  variant='contained'
                  color='primary'
                  fullWidth
                  onClick={handleContactSupport}
                  sx={{
                    mt: 1,
                    py: 1.5,
                    maxWidth: 300
                  }}
                >
                  Contact Support
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
