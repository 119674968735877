import { InstitutionMetrics, Timeseries } from "@interfaces/lab";
import {
  MetricGridType,
  MetricPropsMap,
  MetricType
} from "@interfaces/metrics";
import animals from "@static/icons/animals.svg";
import animalsHover from "@static/icons/animalsHover.svg";
import experiment from "@static/icons/experiment.svg";
import experimentHover from "@static/icons/experimentHover.svg";
import money from "@static/icons/money.svg";
import moneyHover from "@static/icons/moneyHover.svg";
import users from "@static/icons/newusers.svg";
import usersHover from "@static/icons/newusersHover.svg";
import resources from "@static/icons/resources.svg";
import resourcesHover from "@static/icons/resourcesHover.svg";
import transferred from "@static/icons/transferred.svg";
import transferredHover from "@static/icons/transferredHover.svg";

export const metricsGridList = {
  users: {
    label: "Users",
    icon: users,
    hoverIcon: usersHover,
    metricType: MetricGridType.Users
  },
  resourcesShared: {
    label: "Resources shared",
    icon: resources,
    hoverIcon: resourcesHover,
    metricType: MetricGridType.ResourcesShared
  },
  resourcesRequested: {
    label: "Resources requested",
    icon: resources,
    hoverIcon: resourcesHover,
    metricType: MetricGridType.ResourcesRequested
  },
  resourcesTransferred: {
    label: "Resources transferred",
    icon: transferred,
    hoverIcon: transferredHover,
    metricType: MetricGridType.ResourcesTransferred
  },
  animalsSaved: {
    label: "Animals saved",
    icon: animals,
    hoverIcon: animalsHover,
    metricType: MetricGridType.AnimalsSaved
  },
  researchMoneySaved: {
    label: "Resource money saved",
    icon: money,
    hoverIcon: moneyHover,
    metricType: MetricGridType.ResearchMoneySaved
  },
  timeToExperiimentSaved: {
    label: "Time to Experiment saved",
    icon: experiment,
    hoverIcon: experimentHover,
    metricType: MetricGridType.TimeToExperiimentSaved
  }
};

export const metricsTimeFrame = {
  month: "month",
  trimester: "trimester",
  year: "year",
  all_time: "all_time"
};

export const metricsTabDetails = {
  month: { value: "month", label: "30 days" },
  trimester: { value: "trimester", label: "3 months" },
  year: { value: "year", label: "12 months" },
  all_time: { value: "all_time", label: "All Time" }
};

export const initialInstitutionMetrics = {
  institution_id: "",
  timeframe: metricsTimeFrame.month,
  number_of_users: 0,
  cumulative_metrics_data: {
    total_requested_resources: 0,
    total_shared_resources: 0,
    total_transferred_resources: 0,
    total_time_to_experiment_saved: 0,
    total_animals_saved: 0,
    total_money_saved: 0
  },
  snapshot_timeseries: {
    users_timeseries: {},
    resources_shared_timeseries: {},
    resources_requested_timeseries: {},
    resources_transferred_timeseries: {},
    animals_saved_timeseries: {},
    research_money_saved_timeseries: {},
    time_to_experiment_saved_timeseries: {}
  },
  cumulative_timeseries: {
    users_timeseries: {},
    resources_shared_timeseries: {},
    resources_requested_timeseries: {},
    resources_transferred_timeseries: {},
    animals_saved_timeseries: {},
    research_money_saved_timeseries: {},
    time_to_experiment_saved_timeseries: {}
  }
};

export const getMetricValue = (
  key: string,
  metricsGridData: InstitutionMetrics
) => {
  const metrics = {
    users: metricsGridData.number_of_users,
    resourcesShared:
      metricsGridData.cumulative_metrics_data.total_shared_resources,
    resourcesRequested:
      metricsGridData.cumulative_metrics_data.total_requested_resources,
    resourcesTransferred:
      metricsGridData.cumulative_metrics_data.total_transferred_resources,
    animalsSaved: metricsGridData.cumulative_metrics_data.total_animals_saved,
    researchMoneySaved:
      metricsGridData.cumulative_metrics_data.total_money_saved,
    timeToExperiimentSaved:
      metricsGridData.cumulative_metrics_data.total_time_to_experiment_saved
  };
  return metrics[key as keyof typeof metrics] || 0;
};

export const constructMetricsData = (timeseriesData: {
  metricsData: Timeseries;
  institutionId: string;
  timeframe: string;
}) => {
  const metricProps: MetricPropsMap = {
    users: {
      dates: Object.keys(timeseriesData.metricsData.users_timeseries),
      data: Object.values(timeseriesData.metricsData.users_timeseries),
      info: {
        header: "Number of users at your institution.",
        description:
          "The number of active researchers engaging with LabShare within your institution."
      },
      yAxisLabel: "Number of users"
    },
    resourcesShared: {
      dates: Object.keys(
        timeseriesData.metricsData.resources_shared_timeseries
      ),
      data: Object.values(
        timeseriesData.metricsData.resources_shared_timeseries
      ),
      info: {
        header: "Number of resources shared at your institution.",
        description:
          "Total number of resources (e.g., animal strains, cell lines, tissues) made available by your researchers on LabShare."
      },
      yAxisLabel: "Number of resources shared"
    },
    resourcesRequested: {
      dates: Object.keys(
        timeseriesData.metricsData.resources_requested_timeseries
      ),
      data: Object.values(
        timeseriesData.metricsData.resources_requested_timeseries
      ),
      info: {
        header: "Number of resources requested at your institution.",
        description:
          "Total number of resource requests initiated by your researchers through LabShare."
      },
      yAxisLabel: "Number of resources requested"
    },
    resourcesTransferred: {
      dates: Object.keys(
        timeseriesData.metricsData.resources_transferred_timeseries
      ),
      data: Object.values(
        timeseriesData.metricsData.resources_transferred_timeseries
      ),
      info: {
        header: "Number of resources transferred at your institution.",
        description:
          "Total number of resources successfully shared and transferred via LabShare."
      },
      yAxisLabel: "Number of resources transferred"
    },
    animalsSaved: {
      dates: Object.keys(timeseriesData.metricsData.animals_saved_timeseries),
      data: Object.values(timeseriesData.metricsData.animals_saved_timeseries),
      info: {
        header: " Number of animals saved at your institution.",
        description:
          "Estimated number of animals conserved by sharing existing models rather than generating or purchasing new ones."
      },
      yAxisLabel: "Number of animals saved"
    },
    researchMoneySaved: {
      dates: Object.keys(
        timeseriesData.metricsData.research_money_saved_timeseries
      ),
      data: Object.values(
        timeseriesData.metricsData.research_money_saved_timeseries
      ),
      info: {
        header: "Amount of research money saved at your institution.",
        description:
          " Calculated cost savings based on the estimated value of resources shared within your institution."
      },
      yAxisLabel: "Amount of research money saved"
    },
    timeToExperiimentSaved: {
      dates: Object.keys(
        timeseriesData.metricsData.time_to_experiment_saved_timeseries
      ),
      data: Object.values(
        timeseriesData.metricsData.time_to_experiment_saved_timeseries
      ),
      info: {
        header: "Time to experiment saved",
        description:
          " Reduction in time from resource request to experiment initiation through LabShare."
      },
      yAxisLabel: "Time to experiment saved (in weeks)"
    }
  };
  return metricProps;
};
export const formatToDollars = (amount: number) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
};

export const getMetricFormatter = (metricKey: string) => {
  switch (metricKey) {
    case "researchMoneySaved":
      return (value: number | string) => `$${Number(value).toLocaleString()}`;
    case "timeToExperiimentSaved":
      return (value: number | string) => `${value} wks`;
    default:
      return (value: number | string) => Number(value).toLocaleString();
  }
};

export const isTimeSeriesEmpty = (
  timeseriesData: {
    metricsData: Timeseries;
    institutionId: string;
    timeframe: string;
  },
  selectedMetric: MetricType
) => {
  const metricsData = constructMetricsData(timeseriesData);
  const selectedMetricData = metricsData[selectedMetric]?.data || [];
  return (
    selectedMetricData.reduce(
      (sum: number, value: number) => sum + value,
      0
    ) === 0
  );
};
