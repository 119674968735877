import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ResourceAutocomplete } from "@components/AutoComplete/ResourceAutocomplete";
import {
  clearSearchResults,
  searchExternalDb,
  selectDBSearchDone,
  selectDBSearchResults
} from "@features/InsitutionDatabase/resourceSlice";
import { optionsFilter } from "@features/utils/automplete-filter";
import formatResultsTypeNames from "@features/utils/format-results";
import { displayErrorMessages } from "@features/utils/jax-mgi-fields-error";
import { renderStrainName } from "@features/utils/render-strain-name";
import useDebouncedLiveSearch from "@hooks/useDebouncedLiveSearch";
import { CreateResourceFormValues } from "@interfaces/create-resource-form";
import {
  FormikResourceValues,
  ResourceProperties
} from "@interfaces/resource-values";
import {
  Autocomplete,
  Button,
  CircularProgress,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useMediaQuery
} from "@mui/material";
import { FormikProps } from "formik";
import { AppDispatch } from "src/services/store";
import { APP_COLORS } from "src/styles/colors";
import { theme } from "src/styles/theme";

import ManualEntryFields from "./ManualEntryFields";

interface AnimalModelFieldsProps {
  inDialog: boolean;
  disabledFields: Record<string, boolean>;
  setDisabledFields: React.Dispatch<
    React.SetStateAction<{
      strainName: boolean;
      mutation_type_names: boolean;
      target_gene_names: boolean;
      species_name: boolean;
    }>
  >;
  initialValues: CreateResourceFormValues | FormikResourceValues;
  formik: FormikProps<CreateResourceFormValues | FormikResourceValues>;
  resourcePropsNames: ResourceProperties;
  loading: boolean;
  setResourcePropsValues: React.Dispatch<
    React.SetStateAction<ResourceProperties>
  >;
  resourcePropsValues: Record<string, string[]>;
}

const AnimalModelFields: React.FC<AnimalModelFieldsProps> = ({
  inDialog,
  disabledFields,
  setDisabledFields,
  initialValues,
  formik,
  resourcePropsNames,
  loading,
  setResourcePropsValues,
  resourcePropsValues
}) => {
  const dispatch: AppDispatch = useDispatch();
  const isMobile = inDialog
    ? useMediaQuery(theme.breakpoints.down("md"))
    : useMediaQuery(theme.breakpoints.down("sm"));
  const dbSearchDone = useSelector(selectDBSearchDone);
  const dbSearchResults = useSelector(selectDBSearchResults);

  type ResourceFieldKeys = keyof typeof initialValues;

  const handleClear = (field: ResourceFieldKeys) => {
    formik.setFieldValue(field, initialValues[field]);

    setResourcePropsValues((prev) => ({
      ...prev,
      [field]: []
    }));
  };

  const handleClearConnectedFields = () => {
    formik.setFieldValue("strainName", "");
    formik.setFieldValue("mutation_type_names", []);
    formik.setFieldValue("target_gene_names", []);
    formik.setFieldValue("species_name", "");
    setDisabledFields({
      strainName: false,
      mutation_type_names: false,
      target_gene_names: false,
      species_name: false
    });
  };

  useEffect(() => {
    if (dbSearchResults) {
      formik.setFieldValue("strainName", dbSearchResults?.strain_name || "");
      formik.setFieldValue(
        "mutation_type_names",
        dbSearchResults?.mutation_type_names || []
      );
      formik.setFieldValue(
        "target_gene_names",
        dbSearchResults?.target_gene_names || []
      );
      formik.setFieldValue("species_name", dbSearchResults?.species_name || "");
    }
  }, [dbSearchResults]);

  const searchDatabase = async (
    database: string,
    jax: string | undefined,
    mgi: string | undefined
  ) => {
    if (database === "jax" && !jax) return;
    if (database === "mgi" && !mgi) return;
    dispatch(clearSearchResults());
    dispatch(
      searchExternalDb({ database, id: database === "jax" ? jax : mgi })
    );
  };

  const debouncedLiveSearch = useDebouncedLiveSearch(300);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string | null
  ) => {
    const fieldName =
      (inDialog
        ? (formik.values as FormikResourceValues).databaseSelection
        : (formik.values as CreateResourceFormValues).databaseSelect) === "jax"
        ? "jax_external_id"
        : "mgi_external_id";
    formik.setFieldValue(fieldName, value || "");

    if (value) {
      debouncedLiveSearch(
        value,
        inDialog
          ? (formik.values as FormikResourceValues).databaseSelection
          : (formik.values as CreateResourceFormValues).databaseSelect
      );
    }
  };

  return (
    <>
      <Stack spacing={2}>
        <Typography>Add by searching strain database</Typography>
        <Stack
          direction={inDialog ? "row" : "row"}
          alignItems={"center"}
          sx={{
            flexWrap: {
              xs: inDialog ? "wrap" : "wrap",
              md: inDialog ? "nowrap" : "nowrap"
            }
          }}
          gap={2}
        >
          <Stack spacing={2}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"left"}
              gap={2}
            >
              <Typography>Database:</Typography>
              <TextField
                select
                id={inDialog ? "databaseSelection" : "databaseSelect"}
                name={inDialog ? "databaseSelection" : "databaseSelect"}
                value={
                  inDialog
                    ? (formik.values as FormikResourceValues)
                        .databaseSelection ||
                      (!!initialValues.mgi_external_id && "mgi") ||
                      "jax"
                    : (formik.values as CreateResourceFormValues)
                        .databaseSelect || "jax"
                }
                onChange={(event) => {
                  handleClear("jax_external_id");
                  handleClear("mgi_external_id");
                  handleClearConnectedFields();
                  dispatch(clearSearchResults());
                  formik.setFieldValue("databaseSelect", event.target.value);
                  formik.setFieldValue("databaseSelection", event.target.value);
                }}
                fullWidth
              >
                <MenuItem value='jax'>JAX ID</MenuItem>
                <MenuItem value='mgi'>MGI ID</MenuItem>
                <MenuItem value='manual_entry'>Manual Entry</MenuItem>
              </TextField>
            </Stack>
          </Stack>

          {(inDialog
            ? (formik.values as FormikResourceValues).databaseSelection
            : (formik.values as CreateResourceFormValues).databaseSelect) ===
            "jax" && (
            <>
              <ResourceAutocomplete
                excludeAddOption
                id={"jax_external_id"}
                value={formik.values.jax_external_id || ""}
                resourceNames={resourcePropsNames?.jax_external_id || []}
                label={"JAX ID"}
                loading={loading}
                onBlur={formik.handleBlur}
                optionsFilter={optionsFilter}
                onInputChange={(
                  event: React.ChangeEvent<HTMLInputElement>,
                  newInputValue: string | null
                ) => handleInputChange(event, newInputValue)}
                onChange={(_, newValue) => {
                  if (!newValue) {
                    handleClear("jax_external_id");
                    handleClearConnectedFields();
                    dispatch(clearSearchResults());
                  } else {
                    formik.setFieldValue(
                      "jax_external_id",
                      newValue.toString()
                    );
                  }
                }}
                error={
                  formik.touched.databaseSelection &&
                  (inDialog
                    ? (formik.values as FormikResourceValues).databaseSelection
                    : (formik.values as CreateResourceFormValues)
                        .databaseSelect) === "jax" &&
                  Boolean(formik.errors.jax_external_id)
                }
                fullWidth
                helperText={
                  (formik.touched.jax_external_id &&
                    formik.errors.jax_external_id) ||
                  ""
                }
              />
            </>
          )}
          {(inDialog
            ? (formik.values as FormikResourceValues).databaseSelection
            : (formik.values as CreateResourceFormValues).databaseSelect) ===
            "mgi" && (
            <ResourceAutocomplete
              excludeAddOption
              id={"mgi_external_id"}
              resourceNames={resourcePropsNames?.mgi_external_id || []}
              value={formik.values.mgi_external_id || ""}
              label={"MGI ID"}
              loading={loading}
              onInputChange={(
                event: React.ChangeEvent<HTMLInputElement>,
                newInputValue: string | null
              ) => handleInputChange(event, newInputValue)}
              onBlur={formik.handleBlur}
              optionsFilter={optionsFilter}
              onChange={(_, newValue) => {
                if (!newValue) {
                  handleClear("mgi_external_id");
                  handleClearConnectedFields();
                } else {
                  formik.setFieldValue(
                    "mgi_external_id",
                    newValue?.toString() || ""
                  );
                }
              }}
              error={
                formik.touched.databaseSelection &&
                (inDialog
                  ? (formik.values as FormikResourceValues).databaseSelection
                  : (formik.values as CreateResourceFormValues)
                      .databaseSelect) === "mgi" &&
                Boolean(formik.errors.mgi_external_id)
              }
              helperText={
                (formik.touched.mgi_external_id &&
                  formik.errors.mgi_external_id) ||
                ""
              }
              fullWidth
            />
          )}

          {(inDialog
            ? (formik.values as FormikResourceValues).databaseSelection
            : (formik.values as CreateResourceFormValues).databaseSelect) !==
            "manual_entry" && (
            <Button
              type='button'
              size='large'
              variant='contained'
              fullWidth={isMobile ? true : false}
              disabled={
                formik.isSubmitting ||
                (!formik.values.jax_external_id &&
                  !formik.values.mgi_external_id)
              }
              onClick={() =>
                searchDatabase(
                  inDialog
                    ? (formik.values as FormikResourceValues)
                        .databaseSelection || ""
                    : (formik.values as CreateResourceFormValues)
                        .databaseSelect,
                  formik.values.jax_external_id,
                  formik.values.mgi_external_id
                )
              }
            >
              Search
            </Button>
          )}
        </Stack>
      </Stack>

      {
        /* To show errors related to jax/mg required fields */
        (inDialog
          ? (formik.values as FormikResourceValues).databaseSelection
          : (formik.values as CreateResourceFormValues).databaseSelect) !==
        "manual_entry" ? (
          (Boolean(formik.errors.strainName) ||
            Boolean(formik.errors.species_name) ||
            Boolean(formik.errors.target_gene_names) ||
            Boolean(formik.errors.mutation_type_names)) &&
          displayErrorMessages(formik)
        ) : (
          <></>
        )
      }

      {(inDialog
        ? (formik.values as FormikResourceValues).databaseSelection
        : (formik.values as CreateResourceFormValues).databaseSelect) ===
      "manual_entry" ? (
        <ManualEntryFields
          isMobile={isMobile}
          disabledFields={disabledFields}
          setDisabledFields={setDisabledFields}
          initialValues={initialValues}
          formik={formik}
          resourcePropsNames={resourcePropsNames}
          loading={loading}
          setResourcePropsValues={setResourcePropsValues}
          resourcePropsValues={resourcePropsValues}
          handleClearConnectedFields={handleClearConnectedFields}
        />
      ) : (
        <>
          {formik.values.strainName ? (
            <div className='border rounded-lg p-4'>
              <ul>
                {formik.values.strainName && (
                  <li>
                    <Typography>
                      <span className='font-bold'>Strain name:</span>{" "}
                      {renderStrainName(formik.values.strainName)}
                    </Typography>
                  </li>
                )}
                {formik.values.species_name && (
                  <li>
                    <Typography>
                      <span className='font-bold'>Species:</span>{" "}
                      {formik.values.species_name}
                    </Typography>
                  </li>
                )}
                {formik.values.mutation_type_names?.length !== 0 && (
                  <li>
                    <Typography>
                      <span className='font-bold'>Mutation type names:</span>{" "}
                      {Array.isArray(formik.values.mutation_type_names)
                        ? formatResultsTypeNames(
                            formik.values.mutation_type_names
                          )
                        : ""}
                    </Typography>
                  </li>
                )}
                {formik.values.target_gene_names?.length !== 0 && (
                  <li>
                    <Typography>
                      <span className='font-bold'>Target gene names:</span>{" "}
                      {Array.isArray(formik.values.target_gene_names)
                        ? formatResultsTypeNames(
                            formik.values.target_gene_names
                          )
                        : ""}
                    </Typography>
                  </li>
                )}
              </ul>
            </div>
          ) : dbSearchDone ? (
            <div
              className='flex items-center p-4 mb-4 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50'
              role='alert'
            >
              <svg
                className='flex-shrink-0 inline w-4 h-4 me-3'
                aria-hidden='true'
                xmlns='http://www.w3.org/2000/svg'
                fill='currentColor'
                viewBox='0 0 20 20'
              >
                <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z' />
              </svg>
              <span className='sr-only'>Info</span>
              <div>
                <Typography>
                  No resource found for that{" "}
                  {(inDialog
                    ? (formik.values as FormikResourceValues).databaseSelection
                    : (formik.values as CreateResourceFormValues)
                        .databaseSelect) === "mgi"
                    ? "MGI ID"
                    : "JAX ID"}
                  . Please use the Manual Entry option to add the resource.
                </Typography>
              </div>
            </div>
          ) : (
            <></>
          )}
          <Autocomplete
            id={"synonyms"}
            fullWidth
            multiple
            options={[]}
            freeSolo
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            ChipProps={{ color: "primary", variant: "outlined" }}
            filterOptions={optionsFilter}
            getOptionLabel={(option) => option}
            onChange={(_, newValue) => {
              setResourcePropsValues({
                ...resourcePropsValues,
                synonyms: newValue
              });
              formik.setFieldValue("synonyms", (newValue || "").toString());
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                label={"Synonyms"}
                error={
                  formik.touched.synonyms && Boolean(formik.errors.synonyms)
                }
                helperText={
                  (formik.touched.synonyms && formik.errors.synonyms) || ""
                }
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color='inherit' size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
            renderOption={(props, option) => (
              <li
                {...props}
                style={{
                  wordBreak: "break-all",
                  overflowWrap: "break-word",
                  padding: "8px",
                  color: APP_COLORS.subtitleColor
                }}
              >
                <>Add &quot;{option}&quot;</>
              </li>
            )}
          />
        </>
      )}
    </>
  );
};

export default AnimalModelFields;
