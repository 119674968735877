import getError from "@features/utils/get-error";
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction
} from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import api, { cancelToken } from "src/services/api";
import { RootState } from "src/services/store";

// Types
interface User {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  status: string;
  lab?: {
    lab_id: string;
    lab_name: string;
  };
  isActive: boolean;
  roles?: string[];
  datetime_created: Date | string;
  profile_photo_thumbnail_key?: string;

  institution_status?: string;
}

interface AuditLog {
  id: string;
  action: string;
  actorId: string;
  targetId?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  details: Record<string, any>;
  timestamp: string;
}

interface InstitutionAdminState {
  users: User[];
  auditLogs: AuditLog[];
  totalUsers: number;
  totalLogs: number;
  statusCounts: Record<string, number>;
  currentPage: number;
  perPage: number;
  loading: {
    users: boolean;
    auditLogs: boolean;
    statusUpdate: boolean;
    rejection: boolean;
  };
  error: string | null;
}

const initialState: InstitutionAdminState = {
  users: [],
  auditLogs: [],
  totalUsers: 0,
  totalLogs: 0,
  statusCounts: {},
  currentPage: 1,
  perPage: 50,
  loading: {
    users: false,
    auditLogs: false,
    statusUpdate: false,
    rejection: false
  },
  error: null
};

// Async Thunks
export const fetchInstitutionUsers = createAsyncThunk(
  "institutionAdmin/fetchUsers",
  async (
    {
      institutionId,
      params
    }: {
      institutionId: string;
      params: {
        page?: number;
        per_page?: number;
        search_term?: string;
        status_filter?: string;
        lab_id?: string;
      };
    },
    { signal, rejectWithValue }
  ) => {
    const source = cancelToken.source();
    signal.addEventListener("abort", () => source.cancel());

    try {
      const response = await api.get(`/institution/${institutionId}/users`, {
        params,
        cancelToken: source.token
      });
      return response.data.data;
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

export const updateUserStatus = createAsyncThunk(
  "institutionAdmin/updateUserStatus",
  async (
    {
      institutionId,
      userId,
      status
    }: {
      institutionId: string;
      userId: string;
      status: "approved" | "declined";
    },
    { signal, rejectWithValue }
  ) => {
    const source = cancelToken.source();
    signal.addEventListener("abort", () => source.cancel());

    try {
      await api.patch(
        `/institution/${institutionId}/users/${userId}/status`,
        { status },
        { cancelToken: source.token }
      );
      enqueueSnackbar(
        `User ${status === "approved" ? "approved" : "rejected"} successfully`,
        { variant: "success" }
      );
      return { userId, status };
    } catch (error) {
      enqueueSnackbar(`Failed to ${status} user`, { variant: "warning" });
      return rejectWithValue(getError(error));
    }
  }
);

export const fetchAuditLogs = createAsyncThunk(
  "institutionAdmin/fetchAuditLogs",
  async (
    {
      institutionId,
      params
    }: {
      institutionId: string;
      params: {
        start_date?: string;
        end_date?: string;
        action_types?: string[];
        page?: number;
        per_page?: number;
      };
    },
    { signal, rejectWithValue }
  ) => {
    const source = cancelToken.source();
    signal.addEventListener("abort", () => source.cancel());

    try {
      const response = await api.get(
        `/institution/${institutionId}/audit-logs`,
        {
          params,
          cancelToken: source.token
        }
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

export const uploadInstitutionUsers = createAsyncThunk(
  "institutionAdmin/uploadUsers",
  async (
    { institutionId, file }: { institutionId: string; file: File },
    { rejectWithValue }
  ) => {
    try {
      const formData = new FormData();
      formData.append("csv_file", file);

      const response = await api.post(
        `/institution/${institutionId}/users/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          timeout: 300000
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

// Slice
export const institutionAdminSlice = createSlice({
  name: "institutionAdmin",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setPerPage: (state, action: PayloadAction<number>) => {
      state.perPage = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      // Fetch Users
      .addCase(fetchInstitutionUsers.pending, (state) => {
        state.loading.users = true;
        state.error = null;
      })
      .addCase(fetchInstitutionUsers.fulfilled, (state, action) => {
        state.loading.users = false;
        state.users = action.payload.users;
        state.totalUsers = action.payload.total;
        state.currentPage = action.payload.page;
        state.perPage = action.payload.per_page;
        state.statusCounts = action.payload.status_counts;
      })
      .addCase(fetchInstitutionUsers.rejected, (state, action) => {
        state.loading.users = false;
        state.error = action.payload as string;
      })
      // Update User Status
      .addCase(updateUserStatus.pending, (state) => {
        state.loading.statusUpdate = true;
      })
      .addCase(updateUserStatus.fulfilled, (state, action) => {
        state.loading.statusUpdate = false;
        const userIndex = state.users.findIndex(
          (user) => user.id === action.payload.userId
        );
        if (userIndex !== -1) {
          state.users[userIndex].institution_status = action.payload.status;
        }
      })
      .addCase(updateUserStatus.rejected, (state) => {
        state.loading.statusUpdate = false;
      })
      // Fetch Audit Logs
      .addCase(fetchAuditLogs.pending, (state) => {
        state.loading.auditLogs = true;
      })
      .addCase(fetchAuditLogs.fulfilled, (state, action) => {
        state.loading.auditLogs = false;
        state.auditLogs = action.payload.logs;
        state.totalLogs = action.payload.total;
        state.currentPage = action.payload.page;
        state.perPage = action.payload.per_page;
      })
      .addCase(fetchAuditLogs.rejected, (state, action) => {
        state.loading.auditLogs = false;
        state.error = action.payload as string;
      });
  }
});

// Actions
export const { setPage, setPerPage } = institutionAdminSlice.actions;

// Selectors
const getSlice = (state: RootState) => state[institutionAdminSlice.name];

export const selectUsers = createSelector(getSlice, (slice) => slice.users);

export const selectAuditLogs = createSelector(
  getSlice,
  (slice) => slice.auditLogs
);

export const selectLoading = createSelector(getSlice, (slice) => slice.loading);

export const selectTotalUsers = createSelector(
  getSlice,
  (slice) => slice.totalUsers
);

export const selectTotalLogs = createSelector(
  getSlice,
  (slice) => slice.totalLogs
);

export const selectCurrentPage = createSelector(
  getSlice,
  (slice) => slice.currentPage
);

export const selectPerPage = createSelector(getSlice, (slice) => slice.perPage);

export const selectError = createSelector(getSlice, (slice) => slice.error);

export const selectStatusCounts = createSelector(
  getSlice,
  (slice) => slice.statusCounts || {}
);
