import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { getUser } from "@features/Auth/authSlice";
import {
  getInstituitionMembers,
  selectInstituitionMembers,
  selectPage,
  selectQuery,
  setQuery
} from "@features/Lab/labslice";
import { socketCreateRoom } from "@features/sockets/socket-events";
import { constants } from "@features/utils/constants";
import { BASE_S3_URL } from "@features/utils/manage-file";
import { useQuery } from "@hooks/useQuery";
import { LabMember } from "@interfaces/lab";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Autocomplete,
  Avatar,
  Box,
  Grid,
  IconButton,
  Stack,
  styled,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import close from "@static/icons/close.svg";
import createMessage from "@static/icons/create-message.svg";
import { useFormik } from "formik";
import { DashboardContent } from "src/layout/dashboard";
import { AppDispatch, RootState } from "src/services/store";
import { APP_COLORS } from "src/styles/colors";

import ChatView from "./components/ChatView";
import MessageList from "./components/MessageList";
import { getRoomMessages, selectRoomMessages } from "./messagingSlice";

export default function MessagingPage() {
  const theme = useTheme<Theme>();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { room_id } = useParams();

  const currentRoom = useSelector(
    (state: RootState) => state.socket.currentRoom
  );

  const roomMessages = useSelector(selectRoomMessages);

  const dispatch: AppDispatch = useDispatch();
  const currentUser = useSelector(getUser);
  const [newChatUser, setNewChatUser] = useState<LabMember>();
  const [showUsersToMessage, setShowUsersToMessage] = useState<boolean | null>(
    false
  );

  const uniMembers: LabMember[] = useSelector(selectInstituitionMembers);

  const query = useQuery();
  const searchQuery = useSelector(selectQuery);
  const page = useSelector(selectPage);
  const [prevPage, setPrevPage] = useState(page);
  const [prevQuery, setPrevQuery] = useState(searchQuery);

  const [showMessageList, setShowMessageList] = useState(true);
  const [inputValue, setInputValue] = React.useState<string>("");

  const [options, setOptions] = React.useState<readonly LabMember[]>([]);
  const [, setOpenMenu] = React.useState(false);

  type FilterParams = {
    inputValue: string;
  };

  const filterOptions = (
    options: LabMember[],
    { inputValue }: FilterParams
  ): LabMember[] => {
    const filtered = options.filter((option) => {
      const lowerInput = inputValue.toLowerCase();
      const firstName = option.first_name.toLowerCase();
      const lastName = option.last_name.toLowerCase();
      const fullName = `${firstName} ${lastName}`;
      const fullNameInv = `${lastName} ${firstName}`;
      const concatenatedName = `${firstName}${lastName}`;
      const concatenatedNameInv = `${lastName}${firstName}`;

      return (
        firstName.includes(lowerInput) ||
        lastName.includes(lowerInput) ||
        fullName.includes(lowerInput) ||
        concatenatedName.includes(lowerInput) ||
        fullNameInv.includes(lowerInput) ||
        concatenatedNameInv.includes(lowerInput)
      );
    });
    return filtered;
  };
  const handleOpenMenu = () => {
    setOpenMenu(true);
    const filteredOptions = uniMembers.filter(
      (member) => member.user_id !== currentUser.id
    );
    setOptions(filteredOptions);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  const CustomPaper = styled("div")(({ theme }) => ({
    boxShadow: "none",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "10px",
    marginTop: 10,
    backgroundColor: theme.palette.background.paper,
    zIndex: 100
  }));

  const handleCreateRoom = async (participant: LabMember) => {
    setNewChatUser(participant);
    setShowUsersToMessage(false);
    dispatch(
      socketCreateRoom({
        room_type: constants.privateChat,
        creator_id: currentUser.id,
        participant_id: participant.user_id
      })
    );
  };

  const formik = useFormik({
    initialValues: {
      members: []
    },
    onSubmit: () => {
      formik.resetForm();
    }
  });

  useEffect(() => {
    if (currentRoom) {
      const basePath = "/dashboard/messaging";
      const newUrl = `${basePath}/${currentRoom}`;

      window.history.replaceState({ path: newUrl }, "", newUrl);
    }
  }, [roomMessages]);

  useEffect(() => {
    if (room_id) {
      dispatch(getRoomMessages({ roomId: room_id }));
    }
  }, [getRoomMessages]);

  useEffect(() => {
    dispatch(
      getInstituitionMembers({
        institutionId: currentUser.institutionId,
        page: 1,
        limit: 1000
      })
    );
  }, [dispatch, currentUser.institutionId]);

  useEffect(() => {
    const queryText = query.get("query") || "";
    if (queryText !== searchQuery) {
      dispatch(setQuery(queryText));
    }
  }, [dispatch, query, searchQuery]);

  useEffect(() => {
    if (prevPage !== page || prevQuery !== searchQuery) {
      dispatch(
        getInstituitionMembers({
          institutionId: currentUser.institutionId,
          page,
          search: searchQuery
        })
      );
      setPrevPage(page);
      setPrevQuery(searchQuery);
    }
  }, [
    dispatch,
    currentUser.institutionId,
    page,
    searchQuery,
    prevPage,
    prevQuery
  ]);

  return (
    <DashboardContent>
      <Box
        sx={{
          maxWidth: "900px",
          margin: "0 auto",
          backgroundColor: `${APP_COLORS.white}`,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
          height: { xs: "calc(100vh - 120px)", sm: "calc(100vh - 160px)" }
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 2,
            border: "1px solid",
            borderColor: "grey.300",
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            padding: 2
          }}
        >
          <Typography
            variant='h6'
            sx={{
              fontSize: { xs: "1rem", sm: "1.25rem" }
            }}
          >
            Messaging
          </Typography>
          <IconButton
            onClick={() => {
              setShowUsersToMessage(true);
              setShowMessageList(false);
            }}
          >
            <img src={createMessage} alt='create-message' />{" "}
          </IconButton>
        </Box>

        <Grid
          container
          sx={{
            border: "1px solid",
            borderColor: "grey.300",
            borderTop: "none",
            flexDirection: isMobile ? "column" : "row",
            borderBottomRightRadius: 8,
            borderBottomLeftRadius: 8,
            height: "calc(100% - 80px)"
          }}
        >
          {(showMessageList || !isMobile) && (
            <Grid
              item
              xs={isMobile ? 12 : 4}
              sx={{
                borderRight: isMobile ? "none" : "1px solid",
                borderColor: "grey.300",
                overflowY: "auto",
                maxHeight: "calc(100vh - 220px)"
              }}
            >
              <MessageList setShowMessageList={setShowMessageList} />
            </Grid>
          )}

          {/* Chat View */}
          {!showMessageList || !isMobile ? (
            <Grid
              item
              xs={isMobile ? 12 : 8}
              sx={{
                paddingLeft: isMobile ? 0 : 2,
                height: "100%"
              }}
            >
              {isMobile && (
                <IconButton onClick={() => setShowMessageList(true)}>
                  <ArrowBackIcon />
                </IconButton>
              )}
              {showUsersToMessage === true ? (
                <Box p={2} height='100%'>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderColor: "grey.300",
                      marginBottom: "20px"
                    }}
                  >
                    <Typography
                      variant='h6'
                      sx={{
                        fontSize: { xs: "1rem", sm: "1.25rem" }
                      }}
                    >
                      New message
                    </Typography>
                    <IconButton onClick={() => setShowUsersToMessage(false)}>
                      <img src={close} alt='create-message' />{" "}
                    </IconButton>
                  </Box>
                  <Autocomplete
                    options={options}
                    value={formik.values.members.filter(
                      (option) => typeof option !== "string" || !option
                    )}
                    ChipProps={{ style: { display: "none" } }}
                    isOptionEqualToValue={(option, value) =>
                      typeof option !== "string" &&
                      typeof value !== "string" &&
                      option.user_id === value?.user_id
                    }
                    getOptionLabel={(option) =>
                      typeof option === "string"
                        ? option
                        : `${option.first_name} ${option.last_name}`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          formik.touched.members &&
                          Boolean(formik.errors.members)
                        }
                      />
                    )}
                    onChange={(e, value) => {
                      formik.setFieldValue("members", value);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue, reason) => {
                      if (reason !== "reset") {
                        setInputValue(newInputValue);
                      }
                    }}
                    PaperComponent={CustomPaper}
                    renderOption={(props, option: LabMember | string) =>
                      typeof option === "string" ? null : (
                        <li {...props} onClick={() => handleCreateRoom(option)}>
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            width={"100%"}
                          >
                            <Stack
                              direction={"row"}
                              gap={2}
                              alignItems={"center"}
                            >
                              <Avatar
                                src={
                                  !option?.profile_photo_thumbnail_key ||
                                  option.profile_photo_thumbnail_key.length ===
                                    0
                                    ? "DefaultProfile"
                                    : `${BASE_S3_URL}${option.profile_photo_thumbnail_key}`
                                }
                              />
                              <Stack>
                                <Typography variant='body1'>
                                  {`${option.first_name} ${option.last_name}`}
                                </Typography>
                                <Typography
                                  variant='body2'
                                  color='textSecondary'
                                >
                                  {`${option.lab_name}, ${option.institution_name}`}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Stack>
                        </li>
                      )
                    }
                    filterOptions={filterOptions}
                    freeSolo
                    autoHighlight
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    multiple
                    id='free-solo-members'
                    onOpen={handleOpenMenu}
                    onClose={handleCloseMenu}
                  />
                </Box>
              ) : null}
              {showUsersToMessage === false ? (
                <Box height='100%'>
                  <ChatView newChatUser={newChatUser} />
                </Box>
              ) : null}
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </DashboardContent>
  );
}
