import { enqueueSnackbar } from "notistack";

type ResourceLinkType = "post" | "member" | "lab";

interface ResourcePathMap {
  post: string;
  member: string;
  lab: string;
}
export const handleShare = (
  resourceLinkType: ResourceLinkType,
  resourceLinkId: string
) => {
  const pathMap: ResourcePathMap = {
    post: `posts/${resourceLinkId}`,
    member: `member/${resourceLinkId}`,
    lab: `lab?lab_id=${resourceLinkId}`
  };

  const resourceUrl = `${window.location.origin}/dashboard/${pathMap[resourceLinkType]}`;

  navigator.clipboard
    .writeText(resourceUrl)
    .then(() => {
      enqueueSnackbar("Link copied.", { variant: "success" });
    })
    .catch(() => {
      enqueueSnackbar("Sorry, something went wrong.", { variant: "warning" });
    });
};
