import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ResourceAutocomplete } from "@components/AutoComplete/ResourceAutocomplete";
import {
  clearSearchResults,
  searchExternalDb,
  selectDBSearchDone,
  selectDBSearchResults
} from "@features/InsitutionDatabase/resourceSlice";
import { optionsFilter } from "@features/utils/automplete-filter";
import useDebouncedLiveSearch from "@hooks/useDebouncedLiveSearch";
import { CreateResourceFormValues } from "@interfaces/create-resource-form";
import {
  FormikResourceValues,
  ResourceProperties
} from "@interfaces/resource-values";
import {
  Button,
  Link,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useMediaQuery
} from "@mui/material";
import { FormikProps } from "formik";
import { AppDispatch } from "src/services/store";
import { APP_COLORS } from "src/styles/colors";
import { theme } from "src/styles/theme";

import ManualEntryCellLineFields from "./ManualCellLineFields";

interface CellLineFieldsProps {
  formik: FormikProps<CreateResourceFormValues | FormikResourceValues>;
  resourcePropsNames: ResourceProperties;
  loading: boolean;
  setResourcePropsValues: React.Dispatch<
    React.SetStateAction<ResourceProperties>
  >;
  resourcePropsValues: Record<string, string[]>;
  inDialog: boolean;
}

const CellLineFields: FC<CellLineFieldsProps> = ({
  formik,
  resourcePropsNames,
  loading,
  setResourcePropsValues,
  resourcePropsValues,
  inDialog
}) => {
  const dispatch: AppDispatch = useDispatch();
  const isMobile = inDialog
    ? useMediaQuery(theme.breakpoints.down("md"))
    : useMediaQuery(theme.breakpoints.down("sm"));
  const dbSearchDone = useSelector(selectDBSearchDone);
  const dbSearchResults = useSelector(selectDBSearchResults);

  const [disabledFields, setDisabledFields] = useState({
    cellLineName: false,
    cellLineSpeciesName: false,
    tissue_name: false,
    disease_name: false
  });

  useEffect(() => {
    if (!formik.values.cellLineDBSelect) {
      formik.setFieldValue("cellLineDBSelect", "atcc");
    }
  }, []);

  useEffect(() => {
    if (dbSearchResults) {
      formik.setFieldValue(
        "cellLineName",
        dbSearchResults?.cell_line_name || ""
      );
      formik.setFieldValue(
        "cellLineSpeciesName",
        dbSearchResults?.species_name || ""
      );
      formik.setFieldValue("tissue_name", dbSearchResults?.tissue_name || "");
      formik.setFieldValue("disease_name", dbSearchResults?.disease_name || "");
    }
  }, [dbSearchResults]);

  const handleClearConnectedFields = () => {
    formik.setFieldValue("cellLineName", "");
    formik.setFieldValue("cellLineSpeciesName", "");
    formik.setFieldValue("tissue_name", "");
    formik.setFieldValue("disease_name", "");
    setDisabledFields({
      cellLineName: false,
      cellLineSpeciesName: false,
      tissue_name: false,
      disease_name: false
    });
  };

  const searchDatabase = async (atccId: string | undefined) => {
    if (!atccId) return;
    dispatch(clearSearchResults());
    dispatch(searchExternalDb({ database: "atcc", id: atccId }));
  };

  const debouncedLiveSearch = useDebouncedLiveSearch(300);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string | null
  ) => {
    formik.setFieldValue("atcc_external_id", value || "");

    if (value) {
      debouncedLiveSearch(value, "atcc");
    }
  };

  return (
    <>
      <Stack spacing={2}>
        <Typography>Add by searching ATCC database</Typography>
        <Stack
          direction={inDialog ? "row" : "row"}
          alignItems='center'
          sx={{
            flexWrap: {
              xs: inDialog ? "wrap" : "wrap",
              md: inDialog ? "nowrap" : "nowrap"
            }
          }}
          gap={2}
        >
          <Stack spacing={2}>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='left'
              gap={2}
            >
              <Typography>Database:</Typography>
              <TextField
                select
                id='cellLineDBSelect'
                name='cellLineDBSelect'
                value={formik.values.cellLineDBSelect || "atcc"}
                onChange={(event) => {
                  handleClearConnectedFields();
                  dispatch(clearSearchResults());
                  formik.setFieldValue("cellLineDBSelect", event.target.value);
                }}
                fullWidth
              >
                <MenuItem value='atcc'>ATCC ID</MenuItem>
                <MenuItem value='manual_entry'>Manual Entry</MenuItem>
              </TextField>
            </Stack>
          </Stack>

          {formik.values.cellLineDBSelect === "atcc" && (
            <>
              <ResourceAutocomplete
                excludeAddOption
                id='atcc_external_id'
                value={formik.values.atcc_external_id || ""}
                resourceNames={resourcePropsNames?.atcc_external_id || []}
                label='ATCC ID'
                loading={loading}
                onBlur={formik.handleBlur}
                optionsFilter={optionsFilter}
                onInputChange={(
                  event: React.ChangeEvent<HTMLInputElement>,
                  newInputValue: string | null
                ) => handleInputChange(event, newInputValue)}
                onChange={(_, newValue) => {
                  if (!newValue) {
                    formik.setFieldValue("atcc_external_id", "");
                    handleClearConnectedFields();
                    dispatch(clearSearchResults());
                  } else {
                    formik.setFieldValue(
                      "atcc_external_id",
                      newValue.toString()
                    );
                  }
                }}
                error={
                  formik.touched.cellLineDBSelect &&
                  formik.values.cellLineDBSelect === "atcc" &&
                  Boolean(formik.errors.atcc_external_id)
                }
                fullWidth
                helperText={
                  (formik.touched.atcc_external_id &&
                    formik.errors.atcc_external_id) ||
                  ""
                }
              />
            </>
          )}

          {formik.values.cellLineDBSelect === "atcc" && (
            <Button
              type='button'
              size='large'
              variant='contained'
              fullWidth={isMobile}
              disabled={formik.isSubmitting || !formik.values.atcc_external_id}
              onClick={() => searchDatabase(formik.values.atcc_external_id)}
            >
              Search
            </Button>
          )}
        </Stack>
      </Stack>

      {formik.values.cellLineDBSelect === "manual_entry" ? (
        <ManualEntryCellLineFields
          isMobile={isMobile}
          disabledFields={disabledFields}
          setDisabledFields={setDisabledFields}
          formik={formik}
          resourcePropsNames={resourcePropsNames}
          loading={loading}
          setResourcePropsValues={setResourcePropsValues}
          resourcePropsValues={resourcePropsValues}
          handleClearConnectedFields={handleClearConnectedFields}
        />
      ) : (
        <>
          {formik.values.cellLineName ? (
            <div className='border rounded-lg p-4'>
              <ul>
                {formik.values.cellLineName && (
                  <li>
                    <Typography>
                      <span className='font-bold'>Cell Line Name:</span>{" "}
                      {formik.values.cellLineName}
                    </Typography>
                  </li>
                )}
                {formik.values.cellLineSpeciesName && (
                  <li>
                    <Typography>
                      <span className='font-bold'>Species:</span>{" "}
                      {formik.values.cellLineSpeciesName}
                    </Typography>
                  </li>
                )}
                {formik.values.atcc_external_id && (
                  <li>
                    <Typography>
                      <span className='font-bold'>ATCC ID:</span>{" "}
                      <Link
                        color={APP_COLORS.black}
                        target='_blank'
                        rel='noreferrer'
                        className='underline'
                        href={`https://www.atcc.org/products/${formik.values.atcc_external_id}`}
                      >
                        {formik.values.atcc_external_id}
                      </Link>
                    </Typography>
                  </li>
                )}
                {formik.values.tissue_name && (
                  <li>
                    <Typography>
                      <span className='font-bold'>Tissue:</span>{" "}
                      {formik.values.tissue_name}
                    </Typography>
                  </li>
                )}
                {formik.values.disease_name && (
                  <li>
                    <Typography>
                      <span className='font-bold'>Disease:</span>{" "}
                      {formik.values.disease_name}
                    </Typography>
                  </li>
                )}
                {formik.values.morphology && (
                  <li>
                    <Typography>
                      <span className='font-bold'>Morphology:</span>{" "}
                      {formik.values.morphology}
                    </Typography>
                  </li>
                )}
              </ul>
            </div>
          ) : dbSearchDone ? (
            <div
              className='flex items-center p-4 mb-4 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50'
              role='alert'
            >
              <svg
                className='flex-shrink-0 inline w-4 h-4 me-3'
                aria-hidden='true'
                xmlns='http://www.w3.org/2000/svg'
                fill='currentColor'
                viewBox='0 0 20 20'
              >
                <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z' />
              </svg>
              <span className='sr-only'>Info</span>
              <div>
                <Typography>
                  No resource found for that ATCC ID. Please use the Manual
                  Entry option to add the resource.
                </Typography>
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default CellLineFields;
