import React, { FC, ReactNode } from "react";

import clsx from "@features/utils/clsx";
import { Typography } from "@mui/material";

const flexDirections = {
  row: "flex-row",
  "row-reverse": "flex-row-reverse",
  col: "flex-col",
  "col-reverse": "flex-col-reverse"
};

const flexAlign = {
  stretch: "items-stretch",
  start: "items-start",
  center: "items-center",
  end: "items-end",
  baseline: "items-baseline"
};

const flexJustify = {
  stretch: "justify-items-stretch",
  start: "justify-items-start",
  center: "justify-items-center",
  end: "justify-items-end",
  auto: "justify-items-auto"
};

interface TableColumnProps {
  children: ReactNode;
  className?: string;
  flex?: boolean;
  align?: "stretch" | "start" | "center" | "end" | "baseline";
  justify?: "stretch" | "start" | "center" | "end" | "auto";
  heading?: boolean;
  direction?: "row" | "row-reverse" | "col" | "col-reverse";
  [key: string]: unknown;
}

const TableColumn: FC<TableColumnProps> = ({
  flex,
  align = "center",
  justify = "center",
  heading = false,
  children,
  direction = "col",
  className,
  ...rest
}) =>
  heading ? (
    <th
      className={clsx(
        "px-6 py-3 text-left whitespace-no-wrap bg-gray-200",
        className
      )}
      {...rest}
    >
      <Typography fontWeight='500'>{children}</Typography>
    </th>
  ) : (
    <td className={clsx("px-6 py-4 whitespace-no-wrap", className)} {...rest}>
      {flex ? (
        <div
          className={clsx(
            "flex",
            flexDirections[direction],
            flexAlign[align],
            flexJustify[justify]
          )}
        >
          {children}
        </div>
      ) : (
        children
      )}
    </td>
  );

export default TableColumn;
