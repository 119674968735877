import React from "react";

import { columns } from "@features/utils/constants";
import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel
} from "@mui/material";

interface ColumnSelectDialogProps {
  open: boolean;
  onClose: () => void;
  selectedColumns: string[];
  onColumnSelect: (columns: string[]) => void;
}

const ColumnSelectDialog: React.FC<ColumnSelectDialogProps> = ({
  open,
  onClose,
  selectedColumns,
  onColumnSelect
}) => {
  const handleCheckboxChange = (columnId: string) => {
    const updatedColumns = selectedColumns.includes(columnId)
      ? selectedColumns.filter((id) => id !== columnId)
      : [...selectedColumns, columnId];

    onColumnSelect(updatedColumns);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          marginTop: { xs: "10vh", sm: "-8vh" },
          marginLeft: { xs: "5vw", sm: "75vw" },
          maxWidth: "400px",
          width: { xs: "90%", sm: "400px" }
        }
      }}
    >
      <DialogTitle sx={{ marginBottom: "-16px" }}>
        Add/Remove Columns
      </DialogTitle>
      <DialogContent>
        <Box display='flex' flexDirection='column'>
          {columns.map((column) => (
            <FormControlLabel
              sx={{ marginBottom: "-12px" }}
              key={column.id}
              control={
                <Checkbox
                  checked={selectedColumns.includes(column.id)}
                  onChange={() => handleCheckboxChange(column.id)}
                />
              }
              label={column.label}
            />
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ColumnSelectDialog;
