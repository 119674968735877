import React, { FC, ReactNode } from "react";

import clsx from "@features/utils/clsx";

interface TableHeadProps {
  children: ReactNode;
  className?: string;
}

const TableHead: FC<TableHeadProps> = ({ children, className }) => (
  <thead className={clsx("bg-cool-gray-50", className)}>{children}</thead>
);

export default TableHead;
