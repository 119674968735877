import React, { FC, ReactNode, useMemo } from "react";

import clsx from "@features/utils/clsx";

interface TableProps {
  children: ReactNode;
  className?: string;
  disableStyle?: boolean;
  disableScroll?: boolean;
  disableBorderBottom?: boolean;
  withinCard?: boolean;
}

const Table: FC<TableProps> = ({
  children,
  className,
  disableStyle = false,
  disableScroll,
  disableBorderBottom,
  withinCard
}) => {
  const content = useMemo(() => {
    if (disableScroll) {
      return (
        <table className='w-full table-fixed divide-y divide-gray-200'>
          {children}
        </table>
      );
    }

    return (
      <div className='overflow-x-auto'>
        <div className='align-middle inline-block min-w-full'>
          <div
            className={clsx("overflow-hidden", {
              "border-b border-gray-200": !disableBorderBottom
            })}
          >
            <table className='min-w-full divide-y divide-gray-200'>
              {children}
            </table>
          </div>
        </div>
      </div>
    );
  }, [children, disableScroll, disableBorderBottom]);

  return (
    <div
      className={clsx(
        "flex flex-col",
        {
          "rounded bg-white": !disableStyle,
          border: !withinCard
        },
        className
      )}
    >
      {content}
    </div>
  );
};

export default Table;
