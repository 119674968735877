import {
  removePhoto,
  updatePhoto,
  uploadPhoto
} from "@features/Profile/profileSlice";
import getError from "@features/utils/get-error";
import User from "@interfaces/user";
import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import api, { cancelToken } from "src/services/api";
import { RootState } from "src/services/store";

export const getCurrentUser = createAsyncThunk(
  "getCurrentUser",
  async (_, { signal, rejectWithValue }) => {
    const source = cancelToken.source();

    signal.addEventListener("abort", () => {
      source.cancel();
    });

    try {
      const response = await api.get("/current_user", {
        cancelToken: source.token
      });
      return response.data.data;
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

export const logout = createAsyncThunk(
  "logout",
  async (_, { signal, rejectWithValue }) => {
    const source = cancelToken.source();

    signal.addEventListener("abort", () => {
      source.cancel();
    });

    try {
      const response = await api.post("/logout", {
        cancelToken: source.token
      });
      if (response.data.data?.logout_url) {
        // For Columbia users, return the logout URL
        return {
          ...response.data,
          isColumbia: true,
          logoutUrl: response.data.data.logout_url
        };
      } else {
        return response.data;
      }
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async (
    { email, password }: { email: string; password: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.post("/login", { email, password });
      return response.data.data;
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

export const sendVerificationEmail = createAsyncThunk(
  "auth/sendVerificationEmail",
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;

    const userEmail = state?.auth.user.email;
    try {
      const response = await api.post("/new_verification");
      enqueueSnackbar(`Verifcation email sent to ${userEmail}`, {
        variant: "success"
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  },
  {
    condition: (_, { getState }) => {
      const state = getState() as RootState;
      const userEmail = state?.auth.user.email;
      // Preventing the thunk from running if there is no user email in the state
      if (!userEmail) {
        return false; // will not be executed
      }
      return true; // will be executed
    }
  }
);

export const verifyEmail = createAsyncThunk(
  "auth/verifyEmail",
  async (code: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.post("/verify", { code });
      return response.data;
    } catch (error: unknown) {
      if (error instanceof Error) {
        enqueueSnackbar(error.message, { variant: "warning" });
      }

      if (typeof error === "object" && error !== null && "response" in error) {
        const httpError = error as {
          response: { status: number; data: { errors: string[] } };
        };

        if (httpError.response.status === 400) {
          if (
            httpError.response.data.errors.some(
              (err) => err === "Incorrect verification code"
            )
          ) {
            enqueueSnackbar(
              "Verification code is incorrect. Please try again.",
              {
                variant: "warning"
              }
            );
          } else if (
            httpError.response.data.errors.some(
              (err) => err === "Verification code has expired"
            )
          ) {
            dispatch(sendVerificationEmail());
          } else {
            enqueueSnackbar(
              "Sorry, there was an error. Please submit user feedback.",
              { variant: "warning" }
            );
          }
        }
      }

      return rejectWithValue(getError(error));
    }
  }
);

export const setInitialPassword = createAsyncThunk(
  "auth/setInitialPassword",
  async (
    { token, password }: { token: string; password: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.post("/auth/set-initial-password", {
        token,
        password
      });
      enqueueSnackbar("Password set successfully. Please log in.", {
        variant: "success"
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

interface InitialState {
  loading: boolean;
  error: unknown;
  user: User;
  logoutUrl: string;
}

const initialState: InitialState = {
  loading: false,
  error: null,
  user: {} as User,
  logoutUrl: ""
};

export const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCurrentUser: (state, action: { type: string; payload: User }) => {
      state.user = action.payload;
    },
    updateVerified: (state) => {
      state.user.emailVerified = true;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.user = action.payload;
      })
      .addCase(getCurrentUser.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.user = {} as User;
        if (action.payload.isColumbia) {
          state.logoutUrl = action.payload.logouturl;
        }
      })
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(uploadPhoto.fulfilled, (state, action) => {
        state.user.profilePhotoThumbnailKey = action.payload.thumbnail_key;
        state.user.profilePhotoEditKey = action.payload.edited_key;
      })
      .addCase(updatePhoto.fulfilled, (state, action) => {
        state.user.profilePhotoThumbnailKey = action.payload.thumbnail_key;
        state.user.profilePhotoEditKey = action.payload.edited_key;
      })
      .addCase(removePhoto.fulfilled, (state) => {
        state.user.profilePhotoThumbnailKey = "";
        state.user.profilePhotoEditKey = "";
      });
  }
});

export const { name, actions } = auth;

export const { setCurrentUser, updateVerified } = actions;

/**
 * Selector function to get the auth slice from the Redux state.
 *
 * @param {RootState} state - The root Redux state.
 * @returns {InitialState} - The auth slice from the Redux state.
 */
const getSlice = (state: RootState) => state[name];

//Selector: Get the loading state.
export const getLoading = createSelector(getSlice, (slice) => slice?.loading);

// Selector: Get the error state
export const getAuthError = createSelector(getSlice, (slice) => slice?.error);

export const getUser = createSelector(
  getSlice,
  (slice) => slice?.user || ({} as User)
);

export const getLogoutUrl = createSelector(
  getSlice,
  (slice) => slice?.logoutUrl || ""
);
