import getError from "@features/utils/get-error";
import axios from "axios";
import { enqueueSnackbar } from "notistack";

/**
 * Axios instance for making API requests within the application.
 *
 * This instance is pre-configured with the base URL and request interceptors.
 * It automatically adds the authorization token to request headers if available.
 */
const api = axios.create({
  baseURL: "/api",
  timeout: 10000
});

/**
 * Cancel token for canceling ongoing requests.
 *
 * This token source is used to create a CancelToken for each axios request.
 * It allows cancellation of requests using the AbortController signal.
 *
 * @type {CancelTokenSource}
 */
const cancelToken = axios.CancelToken;

// Intercept axios requests and add any pre-configuration we want
api.interceptors.request.use((options) => {
  // a variable to store the CancelToken for each request
  const cancelTokenSource = cancelToken.source();

  if (options?.signal && !options?.cancelToken) {
    const { signal } = options;

    options.cancelToken = cancelTokenSource.token;

    signal.addEventListener?.("abort", () => cancelTokenSource.cancel());
  }

  return options;
});

api.interceptors.response.use(null, (error) => {
  const { isAxiosError, response } = error;

  // If the error is not an Axios error, or there's no response, reject the promise.
  // This can happen if the request was cancelled, if the server didn't respond,
  // or if some network error occurred.
  if (!isAxiosError || !response) {
    return Promise.reject(error);
  }

  const { config } = response;

  /**
   * In the event you want to display an error differently without
   * a notification, pass "disableErrorAlerts" in your request config.
   */
  if (config?.disableErrorAlerts) {
    return Promise.reject(error);
  }

  const { error: responseError } = getError(error);

  if (responseError.status !== 401) {
    const errorData = responseError.data as Record<string, unknown>;

    if (errorData) {
      if (typeof errorData.error === "string") {
        // Single error message
        enqueueSnackbar(errorData.error, { variant: "warning" });
      } else if (errorData.message) {
        enqueueSnackbar(errorData.message as string, { variant: "warning" });
      } else {
        // Fallback to the error message in the responseError object
        enqueueSnackbar(responseError.data as string, { variant: "warning" });
      }
    } else {
      // Fallback to the error message in the responseError object
      enqueueSnackbar(responseError.data as string, { variant: "warning" });
    }
  }

  return Promise.reject(error);
});

export { cancelToken };
export default api;
