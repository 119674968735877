import React from "react";

import PrivacyPolicyDialog from "@components/Dialogs/PrivacyPolicyDialog";
import TermsOfServiceDialog from "@components/Dialogs/TermsOfServiceDialog";
import { Button, Link, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import briefcase from "@static/icons/briefcase.svg";
import marker from "@static/icons/marker.svg";
import phone from "@static/icons/phone.svg";
import Logo from "@static/labsharelogo_white.svg";
import { APP_COLORS } from "src/styles/colors";

import SocialLinks from "./SocialLinks";

const Footer = () => {
  // set up terms of service modal
  const [openTermsOfService, setOpenTermsOfService] = React.useState(false);
  const handleOpenTermsOfService = () => {
    setOpenTermsOfService(true);
  };
  const handleCloseTermsOfService = () => {
    setOpenTermsOfService(false);
  };

  //set up privacy policy modal
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = React.useState(false);
  const handleOpenPrivacyPolicy = () => {
    setOpenPrivacyPolicy(true);
  };
  const handleClosePrivacyPolicy = () => {
    setOpenPrivacyPolicy(false);
  };

  const year = new Date().getFullYear();
  return (
    <Box
      component='footer'
      sx={{
        background: (theme) => theme.palette.primary.main,
        width: "100%",
        color: "white",
        px: { md: 10, xs: 5 },
        py: 3,
        position: "relative",
        zIndex: 0
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "grid",
          gridTemplateColumns: {
            xs: "1fr",
            sm: "repeat(2, 1fr)",
            md: "repeat(4, 1fr)"
          },
          gap: 10,
          py: 5
        }}
      >
        <Stack spacing={3}>
          <Typography variant='subtitle1' fontWeight='bold'>
            <Box
              component='img'
              sx={{
                objectFit: "contain",
                height: { xs: 30, lg: 40 },
                ml: -1.2
              }}
              alt='LabShare logo'
              src={Logo}
            />
          </Typography>
          <Typography>
            A collaborative platform for researchers to share and discover
            animal models, biospecimens, and more.
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <Typography variant='subtitle1' fontWeight='bold'>
            LEGAL &amp; PRIVACY
          </Typography>
          <Link
            color={APP_COLORS.white}
            underline='hover'
            sx={{ cursor: "default" }}
            onClick={handleOpenPrivacyPolicy}
          >
            Privacy Policy
          </Link>
          <Link
            color={APP_COLORS.white}
            underline='hover'
            sx={{ cursor: "default" }}
            onClick={handleOpenTermsOfService}
          >
            Terms of Service
          </Link>
        </Stack>
        <Stack spacing={3}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography variant='subtitle1' fontWeight='bold'>
              QUICK LINKS
            </Typography>
            <Link href='/about' color={APP_COLORS.white} underline='hover'>
              About Us
            </Link>
            <Link
              href='/get-started'
              color={APP_COLORS.white}
              underline='hover'
            >
              Join LabShare
            </Link>
            <Link href='/faqs' color={APP_COLORS.white} underline='hover'>
              FAQs
            </Link>
          </Box>
          <Button
            variant='outlined'
            href='https://forms.gle/Lz1R9RTdys8upTjF8'
            target='_blank'
            rel='noopener noreferrer'
            sx={{
              color: "white",
              borderColor: "white",
              width: "fit-content",
              mt: 2,
              borderRadius: 10,
              py: 0,
              "&:hover": {
                borderColor: "white"
              }
            }}
          >
            Submit Feedback
          </Button>
        </Stack>
        <Stack spacing={3}>
          <Stack spacing={1}>
            <Typography variant='subtitle1' fontWeight='bold'>
              GET IN TOUCH
            </Typography>
            <Stack direction='row' spacing={1} alignItems='center'>
              <Box
                component='img'
                sx={{
                  objectFit: "contain",
                  height: 15
                }}
                src={marker}
              />
              <Typography>New York, NY</Typography>
            </Stack>
            <Stack direction='row' spacing={1} alignItems='center'>
              <Box
                component='img'
                sx={{
                  objectFit: "contain",
                  height: 15
                }}
                src={briefcase}
              />
              <Typography>
                <Link
                  href='mailto:info@labshareapp.com'
                  color={APP_COLORS.white}
                  underline='hover'
                >
                  info@labshareapp.com
                </Link>
              </Typography>
            </Stack>
            <Stack direction='row' spacing={1} alignItems='center'>
              <Box
                component='img'
                sx={{
                  objectFit: "contain",
                  height: 15
                }}
                src={phone}
              />
              <Typography>+1 (732) 860-8696</Typography>
            </Stack>
          </Stack>
          <SocialLinks />
        </Stack>
      </Box>
      <Box
        sx={{
          textAlign: "left",
          display: "flex",
          alignItems: "center",
          color: "gray.400",
          py: 2
        }}
      >
        <Typography component='small' sx={{ whiteSpace: "nowrap" }}>
          Copyright {year} All rights reserved
        </Typography>
        <Box
          sx={{
            height: 1.1,
            flex: 1,
            display: { xs: "none", sm: "block" },
            backgroundColor: "white",
            ml: 1
          }}
        />
      </Box>
      <PrivacyPolicyDialog
        openPrivacyPolicy={openPrivacyPolicy}
        handleClosePrivacyPolicy={handleClosePrivacyPolicy}
      />
      <TermsOfServiceDialog
        openTermsOfService={openTermsOfService}
        handleCloseTermsOfService={handleCloseTermsOfService}
      />
    </Box>
  );
};

export default Footer;
